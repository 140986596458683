import React from 'react';
import internship1 from '../resources/internship1.pdf';
import internship2 from '../resources/internship2.pdf';

const WelcomePopup = ({ show, closePopup }) => {
    return (
        <>
        <div className='welcome_popup_container' style={{ visibility: show ? 'visible' : 'hidden' }}>
        <div className='close_btn' onClick={closePopup}>&#x2717;</div>
            <div className="obj_container">
                <object data={`${internship1}#toolbar=0&navpanes=0&scrollbar=0`} type="application/pdf" width="100%" height="100%" />
            </div> 
            <div className="obj_container">
                <object data={`${internship2}#toolbar=0&navpanes=0&scrollbar=0`} type="application/pdf" width="100%" height="100%" />
            </div>
        </div>
        </>

    );
};

export default WelcomePopup; 