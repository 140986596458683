import React from 'react'
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom'

const Hero = () => {
  return (
    <section className="sec hero">
      <div className="left">
        <div className="info">
          {/* <h2 className="title">Lorem ipsum dolor</h2> */}
          <h1 className="heading">A New Dimension of Possibilities.</h1>
          <h2 className="sub_heading">Entering to the dimension of possibilities through the immersive interactivity is now in your hands.</h2>
          {/* <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nisi cum temporibus, impedit, aperiam officia quasi obcaecati quae sint libero assumenda culpa, deserunt enim est itaque delectus! Soluta at libero consectetur.</p>
          <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nisi cum temporibus, impedit, aperiam officia quasi obcaecati quae sint libero assumenda culpa, deserunt enim est itaque delectus! Soluta at libero consectetur.</p> */}

        </div>

        <div className="more">
          <Link to="/about" color="primary"  className="hids_button tell_me_more"> Tell me more </Link>
        </div>

      </div>
      <div className="right">
        <img src={require('../images/banner.svg')} loading="lazy" alt="Banner" title="Banner"/>
      </div>
    </section>
  )
}

export default Hero;
