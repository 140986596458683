import React from 'react'

const UseCases = ({ useCases }) => {

  // const useCaseImg = useCases?.img ? require(`../images/usecases/${useCases.img}`) : 'https://placehold.it/400x600?text=No+Image+Available';
  
  return (
      <div className="use_cases">
        <div className="left">
          <h2 className="title">{useCases.title}</h2>
          <img src={require(`../images/uc/${useCases.img}`)} loading="lazy" alt={useCases.title} title={useCases.title} />
        </div>
        <div className="right">
          {useCases.list && useCases.list.length > 0 && useCases.list.map((uc, i) =>
            <div key={i} className="usecases_card">

              <h2 className="heading">{uc.heading}</h2>
              {uc.list && uc.list.length > 0 &&
                uc.list.map((u, j) =>
                  <div key={i + j} className="desc">
                    <h3>{u.heading}</h3>
                    <p>{u.desc}</p>
                  </div>
                )
              }
            </div>
          )}
        </div>

      </div>
  )
}

export default UseCases
