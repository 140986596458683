import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { MainContent } from '../components';
import { useLocation } from "react-router-dom";

const Demo = () => {
  window.scrollTo({ top: 0 });
  const location = useLocation();


  return (
    <MainContent noPadding path={location.pathname} title="Demo for Better Understanding of AR VR MR" desc="Demo for Better Understanding of AR VR MR">
      <div className="demo">
        <div className="demo_jumbotron">
          <h1>Virtual (VR) / Augmented (AR) / Mixed (MR) Reality Platform</h1>
          <p>Hi‘Ds provides true and practical solution for all industry’s product showcasing, e-commerce and training programs. Our platform armed with advanced visualization techniques that enable the creation of a simulated environment to feel the virtual model with accuracy and in a condition of reality. Our emerging  platform helps to reduce operational cost and increase customer satisfaction.</p>
        </div>
        <div className="demo_container">
          <div className="left">
            <div className="info">
              <h2>What you can do with Hi'Ds</h2>
            </div>

            <div className="info">
              <h3>Civil and Construction Product Display</h3>
              <p>Design & configure whole project, individual house, kitchen, hall, office etc. Experience the outcome before it builds. A must have tool for your sales and marketing team. Convincing customer is so easy like never before.</p>
            </div>
            <div className="info">
              <h3>Real Time Interior Design with All Leading Brands</h3>
              <p>2D and 3D interior design images are orlder and not convincing. Now design & configure your house, kitchen, hall, office's interior live in our VR platform with all leading household brands from paint to tiles, electrical equipments to electronic items. Experience the outcome before it builds. </p>
            </div>
            <div className="info">
              <h3>Industrial Training for All Industries</h3>
              <p>Providing training to new hirings or enehencing skills of existing employees or spoting faulty areas of your plant & machinery without going to real field can be done so efficiently and accurately by our AR/VR/MR platform. Hi'Ds provids trainer less training  platform and seemless content access.</p>
            </div>
            <div className="info">
              <h3>Industrial Internship Programes</h3>
              <p>Providing internship to talented students are now not restricted to a fixed number of seat's availbility. Hi'Ds AR/VR platform supports  orgnizations to avail internship program for all aspirants. Same way anyone can apply and avail internship from reputed organizations at their doorstep.</p>
            </div>
            <div className="info">
              <h3>Aviation Training Models</h3>
            </div>


          </div>
          <div className="right">
            <h2>Fill the form to watch the demo</h2>
            <form>
              <TextField variant="outlined" margin="normal" required fullWidth id="email" label="Email Address" name="email" autoComplete="email" autoFocus />
              <TextField variant="outlined" margin="normal" required fullWidth id="name" label="Name" name="name" autoComplete="name" />
              <TextField variant="outlined" margin="normal" required fullWidth id="company" label="Company Name" name="company" autoComplete="company" />
              <TextField variant="outlined" margin="normal" required fullWidth id="phone" label="Phone Number" name="phone" autoComplete="phone" />

              <Button type="submit" fullWidth variant="contained" color="primary" > Sign In </Button>
            </form>
          </div>
        </div>
      </div>
    </MainContent>
  )
}

export default Demo;
