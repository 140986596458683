export const solutions = [
  {
    id: "ar-vr-lab-setup",
    img: "lab-setup.png",
    bgColor: "#265690",
    title: "AR VR Lab Setup",
    heading: "Experience the New Era Learning Method by Setting Up the Virtual and Augmented Reality Lab. AR/VR Lab is a Dedicated Space for the Exploration of Cutting-Edge Immersive Technologies — Physically and Imaginatively",
    subHeading: "Open up the door way for you to visualise the latest technology for the most immersive experience",
    list: [
      "Trainer less training lab",
      "In AR lab, just a phone or a tab can change the traditional way of training",
      "In VR lab, a VR headset make the learing and training more interactive and experimental",
      "Very less VR lab set up charges  as compare to others."
    ],
    shortDesc: [
      "For Educational institutes like college, university, ITI, schools and Industries to make the learning and training process easy.",
      "Get premium quality VR and AR devices in a affordable price.  Free VR and AR contents with 1 year service warranty on VR headset.",
      "Interested in implementing AR/VR at your college, college or corporate permises, but don’t know where to start? Just get connected with Hi'Ds."
    ],
    keyBenefits: [
      {
        title: "Visualization and Experiential Learning in Educational Institutions",
        desc: [
          "Virtual reality allows 2D objects to life and makes visualization a reality. Facilitates learning through gamification and interactivity.",
          "Keeps everyone engaged even while learning difficult topics. Enhances creative thinking. Can be used for practical training. "
        ]
      },
      {
        title: "Advanced Healthcare and Well-Being Tool",
        img: "",
        desc: [
          "AR VR is becoming more accessible and affordable for medical education, imaging, dentistry, nurse training and rehabilitation.",
          "AR VR in healthcare uses to improve patient experience, psychological relief and treatment, data visualization, body mapping, advanced diagnostics, VR surgery run-throughs, AR surgery assistance, medical education and emergency navigation."
        ]
      },
      {
        title: "Production-Driven Businesses and Automotive Industry",
        img: "",
        desc: [
          "Implementing AR VR lab in automotive industry allows every department to act smartly, efficiently and effortlessly.",
          "AR VR lab service's benefits are unmatchable. Benefits are driver experience enhancement, replace long and complex manuals, automate the training process, warehousing is more simpler."
        ]
      }
    ],
    useCases: {
      title: "Benefits for the Sales, Marketing, Strategy, Operations, R&D and Training Team",
      img: "lab-set-up.png",
      list: [
        {
          heading: "Educational Institutions",
          list: [
            {
              heading: "Interactive Learning Activities",
              desc: "Interact with the object through Hi'Ds AR VR MR lab"
            },
            {
              heading: "VR Based Practicals",
              desc: "Experimental learning through Virtual Reality "
            },
            {
              heading: "1000+ Learning Concept",
              desc: "Hundreds of detailed and fully dissectible models for automotive, biological sciences, zoology, earth science, anatomy and more. Learning sessions can be saved and replayed. Model labels can be presented in many different languages. "
            }
          ]
        },
        {
          heading: "Manufacturing Industry",
          list: [
            {
              heading: "Interactive Machinary Operation",
              desc: "Operate and troubleshoot the machinaries by Hi'Ds AR VR tool at AR VR MR lab"
            },
            {
              heading: "Maintenance Work",
              desc: "Imagine one being able to see the status of the machine in front of them just with the help of VR device. With real-time super-imposed digital information, users can go through step-by-step information to repair the machine."
            },
            {
              heading: "Assembly & Quality control",
              desc: "Speed is the foundation of time. There are hundred thousands of small parts and tools, when put together to build the final product. From smart phones to rockets has a set of assembly instructions."
            }
          ]
        },
        {
          heading: "Service Industries",
          list: [
            {
              heading: "Better Product Understanding",
              desc: "Integration of Hi'Ds  AR/VR tool with your company  can help you to provide users with additional information about the product with an immersive visualisation."
            },
            {
              heading: "Time-to-Market in an Immersive Way",
              desc: "Hi'Ds AR/VR brings increased precision to every aspect of the industrial manufacturing process, ensuring not only fast time-to-market, but also better quality of manufactured goods."
            }
          ]
        },
        {
          heading: "Industrial Traning Instituions",
          list: [
            {
              heading: "Augemnted & Virtual Reality Training",
              desc: "Hi'Ds AR & VR opens up a lot of doors when it comes to training individuals to operate the machinery and conduct processes."
            },
            {
              heading: "AR on the Assembly Line",
              desc: "Hi'Ds emerging AR applications give new life to delivering real-time instructions to workers on the front line and can provide the opportunity for guidance on all tasks and overlay images to make assembly more accurate."
            }
          ]
        }
      ]
    },
    features: {
      title: "Designated to Boost You Up",
      list: [
        {
          title: "Package Based Setup Cost",
          desc: [
            "Get resources that fulfills your utmost priority with a reasonable pricing.",
            "HI'Ds offers relevent packages for opting the AR VR MR contents, head-sets and other accessories."
          ]
        },
        {
          title: "Free Trail to Access Industry Based Content ",
          desc: [
            "Get additional trial access to experience the immersive visualisation using Hi'Ds AR VR MR tool at your premises.",
            "Go ahead and  Hi'Ds Industry based AR VR MR contents through our platform to explore the new age learning and training method."
          ]
        },
        {
          title: "Post Lab Setup Service",
          desc: [
            "Hi'Ds 24*7 hassel free support and services,enables our exsting customers experience and enjoy our content without much interuptions.",
            "Hi'Ds offers a routine hardware and system check up service for their clients to maintain our existing customers satisfaction."
          ]
        }
      ]
    },
    additionalFeatures: {
      title: "Access to Hi'Ds World Class AR VR Content Platform ",
      list: [
        {
          title: "Trainer LessTraining",
          desc: [
            "Solve real time problems and upgrading the traditional training method by integrating AR VR to make it a trainer less training program.",
            "Make your training much effort less, even trainer less by setting up AR VR lab at your permises."
          ]
        },
        {
          title: "Process Optimization",
          desc: [
            "Use AR VR lab to make the teaching, training and trainer-trainee engagement process easier.",
            "Save your learning and traning time by using VR and AR."
          ]
        },
        {
          title: "Access to the Kingdom of AR VR Content",
          desc: [
            "Industry and education based interactive and experimental AR and VR contents.",
            "Get subscribed to Hi'Ds AR VR  packages for access the platform to get industry and education ready contents."
          ]
        }
      ]
    },
    meta: {
      title: "AR VR Lab Setup by HiDs in Schools, Colleges and Univerisities",
      desc: "Low Price Augmented Reality (AR) and Virtual reality (VR) Lab Setup in Schools, Colleges and Univerisities for Daily Class Studies, Practicals and Workshops"
    }
  },
  {
    id: "360-virtual-tour",
    img: "360-virtual-tour.svg",
    bgColor: "#494429",
    title: "360° Virtual Tour",
    heading: "Hi'Ds 360° Virtual Tour Provides More Contextual Information and a True-to-Life Representation as Compared to Linear Video Clips or a Series of Still Images.",
    subHeading: "Teleport your customer/ visitors to any corner of your premises by implementing Hi'Ds 360° virtual tour and showcase your facility/services on the internet in a most realistic manner.",
    list: [
      "A good way of branding",
      "Vitrual tour is as similar as live",
      "Effective way of Teleporting",
      "Solutions for  hotels, Shopping mall, Retail stores, educational instituations and industries"
    ],
    shortDesc: [
      "For educational institutes, travel and tourism, hotels and museums.",
      "Get customized 360° virtual tour. Imagine being right there, just similar to reality. It's - Attractive - Aesthetic - Appealing - Advanced",
      "Hi'Ds 360° Virtual tour is an intutive service to showcase and move-around the surroundings of your desired location."
    ],
    keyBenefits: [
      {
        title: "New Age Campus/ Premise Tour",
        img: "",
        desc: [
          "Create a virtual tour of your campus / premises to develop an advance walk though. Lets people reach to your place with the help of virtual tour.",
          "Real estate, hospitality, historic preservations, tourism departments, educational institutions and others can get much benefits from 360 degree virtual tour."
        ]
      },
      {
        title: "Branding Made Easy",
        img: "",
        desc: [
          "Create your foot print by adopting advanced visualisation technology. More exposure, now the location/services are on show 24 hours a day, 7 days a week.",
          "A 360 degree virtual tour will help the organization to showcase their business to bring more customers to the business and stand out from the competition. Listings with virtual tours get clicked on 40% more than listings without virtual tours."
        ]
      },
      {
        title: "Robust Sales",
        img: "",
        desc: [
          "360 degree virtual tour enables confidence in decision-making. It minimizes bounce rate.",
          "More site visitors more frequent returns. More sales in less time - virtual tours are proven to generate sales faster."
        ]
      },
      {
        title: "Saves Time",
        img: "",
        desc: [
          "When you're listing a new property for sale, you might end up receiving hundreds of phone calls from potential clients. Meeting each of them can be time-consuming.",
          "With Hi'Ds 360° virtual tour, your customers can see the property on their laptops or smartphones. Those who are really interested will call and ask for more information. This will free up your time, so you'll be able to focus on other aspects of your business."
        ]
      },
      {
        title: "Get More Website Traffic",
        img: "",
        desc: [
          "This cutting-edge technology can drive massive traffic to your website. As a result, you'll attract more clients.",
          "Reduces website's bounce rate. Low bounce rates result in more traffic and improved search engine rankings. Quality backlinks increase brand awareness, strengthen the organization/business reputation, and drive traffic for years to come."
        ]
      },
      {
        title: "Provide a Realistic Experience",
        img: "",
        desc: [
          "Virtual tours are like a permanent open house. Potential clients can access the tour 24/7 from their office or while on the road.",
          "Hi'Ds  helps you to create an instant sense of ownership. Those who take the tour can easily imagine themselves living in that house."
        ]
      }
    ],
    useCases: {
      title: "Benefits for the Educational Organisations, Travel and Tourism Industry and Real Estate Industry. Marketing Managers, Tour Guides and Operators",
      img: "360-virtual-tour.png",
      list: [
        {
          heading: "Educational Institutions",
          list: [
            {
              heading: "360° Virtual Campus Tour",
              desc: "A 360 degree virtual tour can provide students, parents and visitors, must known information about the institution so that an immediate response is possible."
            },
            {
              heading: "Safety Benefits",
              desc: "It is useful for emergency drills, such as a way to provide fire escape information to classrooms."
            },
            {
              heading: "Branding Solutions",
              desc: "Visitors can easily get to know about the excellence of the institution. Like the name and number of CoEs (Center of Excellence)."
            }
          ]
        }, {
          heading: "Travel and Tourism",
          list: [
            {
              heading: "360° Virtual Tour",
              desc: "Tourists can get a 3D tour of the tourist places before arriving at the destination. 360 degree virtual tour offers extra confidence in the business as clients and customers can view inside"
            },
            {
              heading: "Sales & Marketing Team",
              desc: "360 degree virtual tour helps tour operators for selling destinations, activities, and resorts. It enables people to feel the difference between standard and premium packages and upgrade their travel plans."
            }
          ]
        },
        {
          heading: "Real Estate",
          list: [
            {
              heading: "Instant Sense of Ownership",
              desc: "Virtual tours allow clients to tour the property independently, on their own time and at their own pace, giving them a sense of autonomy and personal connection"
            },
            {
              heading: "Interactive",
              desc: "Virtual tours give the chance to investigate a realistic digital environment – client will be able to visit each room, zeroing on details like light fixtures and artisan tiles, all from their own mobiles/laptops."
            },
            {
              heading: "Robust Sales",
              desc: "An inquiry from a virtual tour comes in, that prospect is better informed about the product or service. Hence conversion ratio is very high."
            }
          ]
        },
        {
          heading: "Consultants",
          list: [
            {
              heading: "HR Consultants",
              desc: "Attract more job applicants. Job applicants now a day research companies before applying. By having a virtual tour you can give an insight of the workspace to potential employees to catch their interest."
            },
            {
              heading: "Corporate Training",
              desc: "Corporate training individuals or organizations can use 360 degree virtual tour for skill enhancement, new skill building, operation optimization, stress less production, quality control and assurance."
            },
            {
              heading: "Event Management",
              desc: "Making social media networking is easy by virtual tour. Bringing people from other locations into the same event by virtual tour is effortless and costless."
            }
          ]
        }
      ]
    },
    features: {
      title: "",
      list: [
        {
          title: "360° Panoromic Video with Animation",
          desc: [
            "An interactive 360º video virtual tour with changing projections that were created in the animation editor.",
            "Hi'Ds 360° Virtual tour is all about  adding some movement to your panorama by embedding video into still panoramas. This examples shows video pinned and aligned within the panorama."
          ]
        },
        {
          title: "Virtual Tours Over Standard Flat Images",
          desc: [
            "Provide detailed visual information to your potential customers/ visitors beyond merely text and one or two still photos. (A photograph is worth 1,000 words, a virtual tour is worth 1,000 photographs).",
          ]
        },
        {
          title: "Engage with a Realistic Experience",
          desc: [
            "By implementing Hi'Ds 360° Virtual tour at your campus or premises More-so, you are able to emphasize key features to entice clients/ Visitors/ into exploring your property."
          ]
        }
      ]
    },
    additionalFeatures: {
      title: "Integrate Hi'Ds 360º Virtual Tour",
      list: [
        {
          title: "AR VR Content",
          desc: [
            "Hi'Ds AR VR content helps your organization's all departments to step forward. Sales & marketing team will have a next generation tool to convenience customers. Operation department will use it for daily operational activities."
          ]
        },
        {
          title: "Cross Browser Compactibility",
          desc: [
            "Hi'Ds 360º virtual tour tool in easy to integrate with your website with all browser compatibility."
          ]
        },
        {
          title: "Navigation With Proper Floor Planning",
          desc: [
            "Hi'Ds 360º virtual tour is interactive; viewers can select which images they would like to view and navigate as they choose."
          ]
        }
      ]
    },
    meta: {
      title: "360 Degree Virtual Tour Solutions by HiDs",
      desc: "360 Degree Virtual Tour is using by Educational Institution Campus, Travel and Tourism, Builders and Developers, Real Estate Agents, Industries and Factories"
    }
  },
  {
    id: "ar-vr-for-k-12-iti-dipolma-schools-eng-colleges",
    img: "k-12.png",
    bgColor: "#548dd4",
    title: "AR/VR For K-12, ITI, Dipolma Schools & Eng. Colleges",
    heading: "A New Dimension to the Curriculum. Bring Virtual Items into the Classroom is Now Possible. Classrooms, Labs & Workshops are at the Finger Tips.",
    subHeading: "Hi'Ds AR and VR technology can make lectures and books more immersive and interactive, which can reduce distraction.",
    list: [
      "Learning can be better through visualisation by AR and VR",
      "Teaching and explaining students can be excellent in AR and VR",
      "Augemented reality visual of NCERT , CHSE Books"
    ],
    shortDesc: [
      "For Students to construct the creativity by boosting up the curosity, For teachers to ease the process of teaching, For trainers to make the trainees more comprehensible, ",
      "Experiment and module based AR and VR contents for every grade. Now classroom, lab and workshop are just some clicks away.",
      "Hi'Ds AR and VR service to meet your organisation’s/ instituions training, teaching and learning requirements with a fully customizable taxonomy."
    ],
    keyBenefits: [
      {
        title: "Immersive Way of Conceptualize Learning  ",
        desc: [
          "AR VR technology empowers learners to explore and learn at their own pace, thus stimulating learning and enhances critical retention.",
          "Hi'Ds Augemnted and Virtual  reality based educational contents boost up the curosity and creativity in a student to learn."
        ]
      },
      {
        title: "\"Doubt\/Confusion\" is Now Just a Word ",
        img: "",
        desc: [
          "Concepts that can be hard to understand with verbal explanations suddenly make sense when students experience them visually.",
          "Hi'Ds providing a better way of memorizing classroom studies and escalate the creativity."
        ]
      },
      {
        title: "AR Enabled Book for Better Understanding the Subject",
        img: "",
        desc: [
          "Hi'Ds AR Books are books which offer students 3D presentations and interactive learning experiences through AR technology.",
          "Capturing that “Wow Factor” is probably one of the most common reasons educators including Hi'Ds AR books."
        ]
      },
      {
        title: "Experimental and Interactive Learning with Hi'Ds AR VR Tool",
        img: "",
        desc: [
          "Increasing  students enthusiasm when learning by using Hi'Ds  AR VR MR .Soft skills, such as collaboration, team work and problem-solving, have become a primary focus for K–12 classrooms.",
          "By creating an immersive and interactive learning experience without the use of textbooks Hi'Ds offers self learning based AR contents and VR  contents for practical."
        ]
      },
      {
        title: "Increasing Engagement",
        img: "",
        desc: [
          "By Hi'Ds relevant  AR VR MR tool, teachers have ideas to create real-world experiences about their learning material.",
          "Hi'Ds AR VR MR tool helps teachers mold their lessons in a way that resonates with students. Mugging up is just a word now."
        ]
      },
      {
        title: "Enriching Literacy",
        img: "",
        desc: [
          "Speaking skills – and in turn writing skills – show a marked improvement in students who have had the opportunity to explore a virtual environment.",
          "The immersive, sensory nature of VR improves the quality of students’ language; the immediacy of the experience is particularly beneficial for those students who struggle to think of what to write."
        ]
      }
    ],
    useCases: {
      title: "Benefits for the Students, Trainees, Primary to higher Secondary students, Teachers , Universities",
      img: "k-12.png",
      list: [
        {
          heading: "K-12 Schools ",
          list: [
            {
              heading: "AR VR in Pre-school Education",
              desc: "The immersive, sensory nature of VR improves speaking skills, deepen understanding, Accelerate retention ability, increases learners engagement."
            },
            {
              heading: "AR VR in Primary Education",
              desc: "The immersive, sensory nature of VR improves speaking skills, deepen understanding, excelerate retention ability, increases learners engagement."
            },
            {
              heading: "AR VR in Secondary Education",
              desc: "Technological tools like AR VR drives engagement, develop learners empathy, allow students to explore more than curriculum."
            }
          ]
        },
        {
          heading: "ITI Schools ",
          list: [
            {
              heading: "Students Inside/Outside Classrooms",
              desc: "AR VR technology enables classrooms, labs and workshops at finger tips. It also allows distance students to participate in tasks “together” without actually being in the same room."
            },
            {
              heading: "Departments & Teachers",
              desc: "AR VR can enable more complex interactions, allowing professors to deliver lectures and interact with students within a immersive environment."
            },
            {
              heading: "For Internship Programs",
              desc: "Equipped with a haptic glove and a VR headset, a student could work on building projects with world class corporate houses across the world. "
            }
          ]
        },
        {
          heading: "Engineering Colleges ",
          list: [
            {
              heading: "Students Inside/Outside Classrooms",
              desc: "AR VR technology enables classrooms, labs and workshops at finger tips. It also allows distance students to participate in tasks “together” without actually being in the same room."
            },
            {
              heading: "Departments & Teachers",
              desc: "AR VR can enable more complex interactions, allowing professors to deliver lectures and interact with students within a realistic environment."
            },
            {
              heading: "For Internship Programs",
              desc: "Equipped with a haptic glove and a VR headset, a student could work on building projects with world class corporate houses across the world. "
            }
          ]
        },
        {
          heading: "Diploma Schools ",
          list: [
            {
              heading: "Students Inside/Outside Classrooms",
              desc: "AR VR technology enables classrooms, labs and workshops at finger tips. It also allows distance students to participate in tasks “together” without actually being in the same room."
            },
            {
              heading: "Departments & Teachers",
              desc: "AR VR can enable more complex interactions, allowing professors to deliver lectures and interact with students within a realistics environment."
            },
            {
              heading: "Machinary and Tool Operation ",
              desc: "AR VR MR technology provides both high-end interactive training & an intensive practice of the industrial work cycle. "
            }
          ]
        }
      ]
    },
    features: {
      title: "",
      list: [
        {
          title: "Trainer Less Training ",
          desc: [
            "Adopt new age learning skills through Hi'Ds AR VR MR tool and learn by yourself without a teacher or trainer.",
            "Hi'Ds AR VR MR tool is dipped with rich contents, purely designed, and developed to get trained by your own."
          ]
        },
        {
          title: "Visual Learning- Learn by Doing",
          desc: [
            "By using Hi'Ds AR VR MR tool students actually visualise the things they’re learning about.",
            "Through Hi'Ds AR VR MR tool, being able to visualize complex functions of machinares or mechanisms of machines makes them easier to comprehend."
          ]
        },
        {
          title: "Access to Hi'Ds AR VR MR Platform",
          desc: [
            "Get unlimited access to the trainerless training contents by subscribing our platform.",
            "With 10000+ contents, Hi'Ds is the world's largest AR VR MR content platform."
          ]
        }
      ]
    },
    additionalFeatures: {
      title: "",
      list: [
        {
          title: "Individual Skill Tracking Facility",
          desc: [
            "Skill tests through AR and VR for a better performance test of the students, individually.",
            "Hi'Ds AR VR MR tool is having the facility to record your learnings and progress records."
          ]
        },
        {
          title: "Eliminates the Language Barrier",
          desc: [
            "The language barrier is usually a problem when it comes to international education, Now its ease to access the contents in your local language also.",
            "Choose the language which you understand better,Hi'Ds very first focus to eliminate this barrier."
          ]
        },
        {
          title: "Don't Just Learn, Start Experiencing the Learning",
          desc: [
            "By using Hi'Ds AR VR MR tool, students can be transported to places and environments which are not readily available to them.",
            "Our AR VR MR contents are fully interactive and experimental based so that a student can experience a better learning process."
          ]
        }
      ]
    },
    meta: {
      title: "AR VR Course Modules for  K12, ITI, Dipolma & Engineering Colleges",
      desc: "Course and Training Modules in Augmented Reality (AR) and Virtual reality (VR) for  K12, LKG, UKG, Primary, High Schools, ITI, Dipolma & Engineering Colleges "
    }
  },
  {
    id: "ar-vr-solutions-in-industrial-training",
    img: "industrial-training.png",
    bgColor: "#4f6128",
    title: "AR/VR Solutions in Industrial Training",
    heading: "On-Field Experience for the Employees and Trainees. A Tool for Higher Management to Indentify the Industrial Problem to Get Corrected Quickly.",
    subHeading: "Hi'Ds AR VR MR tool is a perfect medium for industrial training as it allows you to create immersive solutions and can replicate any possible real-life situation without the risks.",
    list: [
      "Trainer less training",
      "No limit of accessbility to content",
      "Real time porblem solving approach",
      "Easy way of enhencing employee skills"
    ],
    shortDesc: [
      "For industries like : Eduacation, Automobiles, Chemicals, Manufacturing, Production and Agricultural.",
      "Get customised AR VR training modules for employees, trainers and trainee. VR can be used in lab and workshop, where as AR will be work as realtime doubt and problem solver.",
      "Get access to Hi'Ds’s virtual reality platform. Which is tailored to improve the skill and preparedness of the technicans, need in todays work environment."
    ],
    keyBenefits: [
      {
        title: "Production Consistency & Enhancement",
        img: "",
        desc: [
          "AR VR brings increased precision to every aspect of the industrial manufacturing process, ensuring not only fast time-to-market, but also better quality of manufactured goods"
        ]
      },
      {
        title: "Maintenance Made Easy",
        img: "",
        desc: [
          "AR VR technology put employees at the heart of proceedings to acquire and practice essential plant operation and maintenance skills. By setting aside the manuals and information sheets, trainees also stand a better chance of retaining information."
        ]
      },
      {
        title: "Quality Control & Quality Assurance",
        img: "",
        desc: [
          "AR VR application shortens the inspection time and avoids errors that may occur if some elements are overlooked. This technology modernize, optimize and unify the quality control process."
        ]
      },
      {
        title: "Reduced Training Cycle",
        img: "",
        desc: [
          "VR enables the trainee to get hands-on experience with real-time industrial problems by practicing them multiple times, without having to visit the plant in real life.",
          "The continuous practice on all possible critical situations in Hi'Ds AR VR MR tool ,helps in building the trainee’s muscle memory & amplifies the pace of learning."
        ]
      },
      {
        title: "Reduced Risk of Accidents",
        img: "",
        desc: [
          "The increase in retention power & muscle memory by practicing more on the Hi'Ds AR VR MR platform not only reduces the training cycle but also the risk of accidents.",
          "Training on Hi'Ds AR VR MR Tool for industrial training with rich contents in virtual equipment makes it possible for the trainee to learn how to handle the machines involved"
        ]
      },
      {
        title: "Lesser Training Cost",
        img: "",
        desc: [
          "Hi'Ds AR VR MR tool gives a realistic experience while using the simulated devices in the virtual environment which helps in reducing the use of real machines for training.",
          "By using Hi'Ds AR VR MR  the number of hours of machine use reduces in training, the cost of training also reduces. With VR training, there is less use of real industrial assets which eventually lowers the long-term cost of training."
        ]
      }
    ],
    useCases: {
      title: "Benefits for Engineering Colleges, Diploma and ITI Schools ",
      img: "industrial-training.png",
      list: [
        {
          heading: "Manufacturing Department",
          list: [
            {
              heading: "Production Team",
              desc: "AR VR is extensively used in manufacturing assembly. This technology simplifies complex assembly procedures by replacing static work instruction documents."
            },
            {
              heading: "Higher Management",
              desc: "AR VR is using as a tool for visualizing post-control data information, which facilitates the creation of reports and decision making in a short time."
            },
            {
              heading: "Trainees",
              desc: "AR VR creates the same environment for the trainees to work in virtually. Not only does this lead to a better level of training without risks, it also dramatically reduces stress among the trainees, leading to substantially better performances."
            }
          ]
        },
        {
          heading: "Maintenance Department",
          list: [
            {
              heading: "Technicians & Managers",
              desc: "Augmented & Virtual reality has been adopted to ease maintenance processes. The technology allows key procedures to be in direct view of the technicians operating the repairs."
            },
            {
              heading: "Higher Management",
              desc: "AR VR is using as a tool for visualizing post-control data  information, which facilitates the creation of reports and decision making in a short time."
            },
            {
              heading: "Trainees",
              desc: "AR VR creates the same environment for the trainees to work in virtually. Not only does this lead to a better level of training without risks, it also dramatically reduces stress among the trainees, leading to substantially better performances."
            }
          ]
        },
        {
          heading: "Quality Control & Training Department",
          list: [
            {
              heading: "Reinventing Workforce Training",
              desc: "AR VR technology, train plant personnel on critical industrial work activities. It bring new industrial workers up to speed quickly by enhancing training and delivering it in new and contemporary ways."
            },
            {
              heading: "Quality Control (QC) Team",
              desc: "AR VR for quality control processes enables engineers to examine parameters like dimensional accuracy, tolerances, the surface finish, and interference. In addition, engineers can better gauge various component conditions."
            },
            {
              heading: "Quality Assurance (QA) Team",
              desc: "AR VR application shortens the inspection time and avoids errors that may occur if some elements are overlooked. This technology modernize, optimize and unify the quality control process."
            }
          ]
        },
        {
          heading: "Safety Department",
          list: [
            {
              heading: "Call to Action During Emergency",
              desc: "During Emergency AR VR technology guide in a herostic approach. It may be an urgent evacuation or guiding life savers into the premise, this technology's teleporting accuracy unmatchable."
            },
            {
              heading: "Mock Drill",
              desc: "AR VR based industrial safety training programs allow companies to put their workers in potentially dangerous simulated real-world environments — ones that would be too risky and/or expensive in real life."
            },
            {
              heading: "Safety Awareness",
              desc: "More engaging than traditional way of safety awareness. AR VR Interactive sessions provide higher levels of personal engagement and learning retention."
            }
          ]
        }
      ]
    },
    features: {
      title: "",
      list: [
        {
          title: "Product Oriented Content Category",
          desc: [
            "HiDs contents fit to your product line or service. Our industry experts update and enhence contents periodically. ",
            "HiDs AR VR platform is not just a tool for your daily operation, maintence, sales or marketing department. It's a need of time. Our product oriented platform fulfill all your business requirments flawlessly."
          ]
        },
        {
          title: "Access by Smartphone and VR Headset",
          desc: [
            "HiDs AR VR platform is easy to access anywhere from any of the above device. AR contents can be access through smartphone. VR contents required a VR headset.",
            "Our platform is well designed by taking consideration of business needs. We have also taken care of content access flow. Which is helping every individual to access his/her content in just few clicks."
          ]
        },
        {
          title: "Trainer Less Training",
          desc: [
            "HiDs training modules are easy to understand with a self driven strucuture. Which enables every individual to go through the deep learning without anyone's help.",
            "Adopt new way of learning skills by implementing trainer less training program. Hi'Ds connects academic to industries through immersive visualistion technology with better experiene of learning."
          ]
        },
        {
          title: "True Skill Development Tool",
          desc: [
            "HiDs AR VR platform offers immersive learning experiences to employees for developing their human capabilities.",
            "HiDs wide range of industry standard training modules allows each employee and student to upgrade and enhence skill periodically."
          ]
        },
        {
          title: "Realtime Problem Solving Approach",
          desc: [
            "HiDs maintain the quality and accuracy of training & learning modules. Which makes us a true leader during day to day operational issues.",
            "Developing virtual industrial assets to analyze and solve complex problems in real time by implementing Hi'Ds trainer less training program."
          ]
        },
        {
          title: "Dedicated Account Manager",
          desc: [
            "Though HiDs believes in ZERO error, still we provide an account manager for smooth operation.",
            "The account manager is the first and immediate contact 24X7. All kinds of issues can be resolved in same day."
          ]
        }
      ]
    },
    additionalFeatures: {
      title: "",
      list: [
        {
          title: "Content on Demand",
          desc: [
            "We are having 5000+ contents/modules, which are just few clicks away. HiDs covered almost all industries and departments in its platform.",
            "A student or an employee, an educational institute or a corporate house, a manufacturing company or a service industry, HiDs pricing is very competitive and affordable for everyone."
          ]
        },
        {
          title: "Individual Skill Tracking Facility",
          desc: [
            "HiDs unique dashboard summarize and displays all navigations and learnings. This skill tracking facility is helping institutions and individuals to know about the potential growth.",
            "Corporates can utilize this facility during hiring, salary appraisal and promotions."
          ]
        },
        {
          title: "Periodic Assessment Facility",
          desc: [
            "With training and learning modules, HiDs believes in periodic assessment of skill set. Our industry approach assessment mechanism, helps the learner to upgrade or update the skill or knowlegde.",
            "This assessment facility helps individuals and corporates to track the employees skill growth."
          ]
        }
      ]
    },
    meta: {
      title: "AR VR Industrial Training Modules for ITI, Dipolma & Engineering Colleges",
      desc: "Industrial Trainings Using Augmented Reality (AR) and Virtual reality (VR) for ITI, Dipolma & Engineering Colleges Students, Industries and Factories Workers Employees"
    }
  },
  {
    id: "ar-vr-implementation-in-internship",
    img: "internship.svg",
    bgColor: "#974806",
    title: "AR/VR Implementation in Internship",
    heading: "Availing the Internship and Training from Reputed Corporate Houses is Now Easier.",
    subHeading: "Corporate houses from all over the world are waiting to give internships, it's just matter of enrolment. Hi'Ds internship platform is bringing and nurturing interns to flourish.",
    list: [
      "Trainer less training",
      "Recall value is much higher than real training",
      "No limit of accessbility to content",
      "No participatation limits"
    ],
    shortDesc: [
      "For students, working professionals, eduactional institutes and corporate houses.",
      "Get customized internship training modules which are explicitly designed for students to make them highly skilled and young professionals.",
      "Get access to Hi'Ds’s virtual reality platform. intended to provide proactive supports to make student industry ready with advancement of no participation limits in the intrenship program."
    ],
    keyBenefits: [
      {
        title: "Companies Across the World",
        desc: [
          "Hi'Ds tie-uped with companies across the world to provide intership programs and certifications. Hence, everyone has an opportunity to get the best from the best.",
          "Avail internship from 500+ corporate houses in 12 different industries."
        ]
      },
      {
        title: "Internship Program With Your Convenience",
        img: "",
        desc: [
          "Internship through Hi'Ds AR VR platform is not place or premise bounded. Anyone can take the program from anywhere.",
          "Internet, smartphone and a VR headset is enough to avail this facility."
        ]
      },
      {
        title: "Unlimited Access to World Class Content",
        img: "",
        desc: [
          "Get access to industry's best AR VR content bank. Hi'Ds content expert's focus to provide industry focused internships for everyone. Hi'Ds believes \"an Arts graduate can contribute a lot in fashion design\".",
          "Hi'Ds allows interns to access the platform 24X7 with industry's best customer support. The training modules are easy to understand with a self driven strucuture. Which enables every individual to go through the deep learning without anyone's help."
        ]
      },
      {
        title: "Programmes Accredited by Corporate Houses",
        img: "",
        desc: [
          "Hi'Ds is dedicated to maintaining academic quality through accreditation. The accreditation standards are based on quality and the continuous improvement process.",
          "Hi'Ds developed intership programes by colaborating with corporate house's concerned department. Hence the programme is certified and best to industry."
        ]
      },
      {
        title: "Interactions with World Wide Great Minds ",
        img: "",
        desc: [
          "Internship is all about learn new things in a constructive way with real time problem solving skills. Hi'Ds allows interns across the world in mission of brain gain.",
          "Hi'Ds unique interactive platform allows everyone to share and gain knowledge."
        ]
      },
      {
        title: "Affordable Pricing",
        img: "",
        desc: [
          "Hi'Ds internship programs are very affordable. By availing this benefit everyone can save travelling, boarding and lodging cost.",
          "Hi'Ds provides EMI option also. "
        ]
      }
    ],
    useCases: {
      title: "Benefits for the Students, Educational Institutions, Companies and Employees",
      img: "internship-training.png",
      list: [
        {
          heading: "Students",
          list: [
            {
              heading: "ITI, Diploma & Engineering Students",
              desc: "Students of any semester are qualified to take internships from Hi'Ds. This program is a gateway to the practical work experience."
            },
            {
              heading: "Science, Commerce & Arts Graduates",
              desc: "Lot of internship programs are designed for any type of graduates. Now non technical graduates are having equal opprtunities in the race of employability."
            },
            {
              heading: "Job Seekers",
              desc: "No work experience, don't worry. Hi'Ds market approach internship programs will make you ready to hit floor. A lot of companies talent acquisition team is preferring us."
            }
          ]
        },
        {
          heading: "Educational Institutions",
          list: [
            {
              heading: "Placement Department",
              desc: "Placement department of any educational institution's can use Hi'Ds internship program to make their students hireable. Hi'Ds rich content for all industries is an weapon for students to win in the race of employability."
            },
            {
              heading: "Branding & Promotion Department",
              desc: "Every guardian and student is worried about the placement after studies. Now educational institutions can use Hi'Ds platform to make everyone hireable."
            },
            {
              heading: "Management Team",
              desc: "Now by Hi'Ds, internship programes can be done from college itself. Hence institutions also guide their students which program is suit for whom."
            }
          ]
        },
        {
          heading: "Companies",
          list: [
            {
              heading: "Talent Acquisition Professionals",
              desc: "Company's talent acquisition or HR department will get quality and knowledgeable employees from Hi'Ds internship platfom."
            },
            {
              heading: "Branding & Marketing Professionals",
              desc: "Companies can use Hi'Ds platform to showcase their product and services."
            },
            {
              heading: "R & D Department",
              desc: "Hi'Ds AR VR platform enables research and development department to get and engage industry's talents."
            }
          ]
        },
        {
          heading: "Employees",
          list: [
            {
              heading: "Working Professionals",
              desc: "Hi'Ds internship programs are not only for freshers, anyone who wants to enhence or develop the skill are most welcome."
            },
            {
              heading: "Freelancers",
              desc: "Add certifications to the profile, let clients know about your expertise. Hi'Ds allows freelancers to take internship programs to put extra marks in the profile."
            },
            {
              heading: "Consultants",
              desc: "Hi'Ds internship platform allows consultants to interact with industry fit certified persons. It is helping consultants to full fill its clients requirments."
            }
          ]
        }
      ]
    },
    features: {
      title: "",
      list: [
        {
          title: "Internship Programes of All Industries with Less Price",
          desc: [
            "Hi'Ds internships covered all departments across all industries. Students from any stream of any age having equal opportunity to learn and excel."
          ]
        },
        {
          title: "Access by Smartphone and VR Headset",
          desc: [
            "Hi'Ds AR VR internship platform is easy to access anywhere from any of the above device. AR contents can be access through smartphone. VR contents required a VR headset.",
            "Our platform is well designed by taking consideration of business needs. We have also taken care of content access flow. Which is helping every individual to access his/her content in just few clicks."
          ]
        },
        {
          title: "Trainer Less Training",
          desc: [
            "Hi'Ds internship modules are easy to understand with a self driven strucuture. Which enables every individual to go through the deep learning without anyone's help.",
            "Adopt new way of learning skills by implementing trainer less training program. Hi'Ds connects academic to industries through immersive visualistion technology with better experiene of learning."
          ]
        },
        {
          title: "True Skill Development Tool",
          desc: [
            "Hi'Ds AR VR intership programs offers immersive learning experiences. With the help of this program every individual is getting a chance to understand the human capabilities.",
            "Hi'Ds wide range of industry standard training modules allows each employee and student to upgrade and enhance skill periodically."
          ]
        },
        {
          title: "Realtime Problem Solving Approach",
          desc: [
            "Hi'Ds maintain the quality and accuracy of training & learning modules. Which makes us a true leader during day to day operational issues.",
            "Developing virtual industrial assets to analyze and solve complex problems in real time by implementing Hi'Ds trainer less training program."
          ]
        },
        {
          title: "Dedicated Account Manager",
          desc: [
            "Though Hi'Ds believes in ZERO error, still we provide an account manager for smooth operation.",
            "The account manager is the first and immediate contact 24X7. All kinds of issues can be resolved in same day."
          ]
        }
      ]
    },
    additionalFeatures: {
      title: "",
      list: [
        {
          title: "Content on Demand",
          desc: [
            "We are having 5000+ contents/modules, which are just few clicks away. Hi'Ds covered almost all industries and departments in its platform.",
            "A student or an employee, an educational institute or a corporate house, a manufacturing company or a service industry, Hi'Ds pricing is very competitive and affordable for everyone."
          ]
        },
        {
          title: "Individual Skill Tracking Facility",
          desc: [
            "Hi'Ds unique dashboard summarize and displays all navigations and learnings. This skill tracking facility is helping institutions and individuals to know about the potential growth.",
            "Corporates can utilize this facility during hiring, salary appraisal and promotions."
          ]
        },
        {
          title: "Periodic Assessment Facility",
          desc: [
            "With training and learning modules, Hi'Ds believes in periodic assessment of skill set. Our industry approach assessment mechanism, helps the learner to upgrade or update the skill or knowlegde.",
            "This assessment facility helps individuals and corporates to track the employees skill growth."
          ]
        }
      ]
    },
    meta: {
      title: "AR VR Implementation in Internship for All Industries",
      desc: "Inernships Using Augmented Reality (AR) and Virtual reality (VR) from Manufacturing, Service, Consulting , IT, ITs and Other Corporate Companies"
    }
  },
  {
    id: "ar-vr-implementation-in-hospitality-hotels-tourism",
    img: "hotels-tourism.svg",
    bgColor: "#0378ad",
    title: "AR/VR Implementation in Hospitality, Hotels & Tourism",
    heading: "At the cutting Edge of 360 Hi'Ds VR, Virtual Reality Travel Experiences Provide Something Truly Unique and Memorable to The User.",
    subHeading: "Creating Memorable and Unique Experiences For the User, Allowing the User to Imagine Themselves at a Travel Destination",
    list: [
      "360 degree view of hotels and rooms",
      "Virtul tour of the tourist places",
      "Access to content at any place at any time"
    ],
    shortDesc: [
      "For Hospitality & tourism industry.",
      "Get customized 360° virtual tour, AR and VR content for a virtual interaction between service and customer.",
      "The 360° virtual tour accessable through website. AR and VR contents through Hi'Ds platform."
    ],
    keyBenefits: [
      {
        title: "A Mesmerizing Reality Tour ",
        desc: [
          "Hi'Ds Augmented and Virtual Reality (AR VR) platform serves to alter a customer's perception of their physical surroundings.",
          "AR VR technologies provide the ability to teleport to the location and explore the tourist place, hotels, their rooms as well as surrounding areas."
        ]
      },
      {
        title: "Anytime Anywhere Go Virtual",
        img: "",
        desc: [
          "Hi'Ds AR VR platform can be experienced through a smartphone, tablet or VR device. Experience the thrill of travelling even while sitting in your living room.",
          "Our platform is well designed by taking consideration of business and customer needs. We have also taken care of content access flow. Which is helping every individual to get optimum out of it."
        ]
      },
      {
        title: "No Seasonal Obstacles",
        img: "",
        desc: [
          "Now ice in north pole or heavy rain in Puducherry can't stop you to visit there. Hi'Ds AR VR platform provides barrier less travel experince any where through virtual reality.",
          "Hi'Ds VR travel tour is created for a wide range of travel destinations and landmarks. Experience of popular tourism destinations  to give the user a taste of what it’s like to be there."
        ]
      },
      {
        title: "Robust Sales",
        desc: [
          "Hospitality and tourism industry sales completely depends upon the level of facilities and services provided by the business. Hi'Ds platform is a reality showcase of your products and services.",
          "Hi'Ds AR VR platform helps to increse customer's visualization that quicken decision making ability."
        ]
      },
      {
        title: "Branding Made Easy",
        img: "",
        desc: [
          "Hi'Ds VR is utilised in the tourism industry for marketing. Being able to capture tourism destinations in such a memorable and immersive way is a powerful marketing tool.",
          "Hi'Ds VR is allowing the user to experience the feeling of “being there”. Whilst regular images and videos can work well for showing what a destination has to offer, they don’t often elicit an emotional response."
        ]
      },
      {
        title: "Increase Customer Satisfaction",
        img: "",
        desc: [
          "With Hi'Ds platform facilities like virtual booking processes, virtual hotel & room tours, virtual travel experiences and AR VR gamification customer engagement and satisfaction increases.",
          "Hi'Ds AR VR application provides the amount of information that guests tend to ask for, both before they arrive and once they are there."
        ]
      }
    ],
    useCases: {
      title: "Benefits for the Hotels, Tourism, Travel and Food & Beverage Industries",
      img: "tourism.png",
      list: [
        {
          heading: "Hotels",
          list: [
            {
              heading: "Government & Private Hotels",
              desc: "All star and non star hotels can use Hi'Ds AR VR platform to showcase their products and services. Virtual tour of the rooms, ambiences and facilities is creating always an extra impact on customers."
            },
            {
              heading: "Event Management Team",
              desc: "\"Seeing is Believing\" is an integral part of all kinds of event management. Hi'Ds AR VR platform strengthen all event management organizations to showcasing the best to their customers."
            },
            {
              heading: "Branding & Marketing Team",
              desc: "Hi'Ds platform is using by branding and marketing teams to virtual showcasing the products and services in a immersive way. It enables people to feel the difference between standard and premium."
            }
          ]
        },
        {
          heading: "Tourism",
          list: [
            {
              heading: "Hotels & Resorts",
              desc: "Tourism is all about enjoying the moment. Hotels and resorts can use Hi'Ds AR VR platform to showcase their products and services. This virtual reality helps for quick decision."
            },
            {
              heading: "Tourism Department's for Branding & Marketing Team",
              desc: "Hi'Ds platform is an unmatched tool to aware about the tourist destinations and its beauty. Tourism department's branding and marketing team is using AR VR platform to attract customers through out the world."
            },
            {
              heading: "Travel Agents, & Clubs",
              desc: "Holiday planning clubs and travel agent's also use Hi'Ds AR VR platform to marketing their best services."
            }
          ]
        },
        {
          heading: "Travel",
          list: [
            {
              heading: "Airlines, Trains, Bus and Taxi Services",
              desc: "Airlines, trains, bus and taxi service providing companies can do sales, branding and marketing in a customer centric way by using Hi'Ds AR VR platform."
            },
            {
              heading: "Travel Agents, & Clubs",
              desc: "Holiday planning clubs and travel agent's also use Hi'Ds AR VR platform to marketing their best services."
            },
            {
              heading: "Tourism Department's for Branding & Marketing Team",
              desc: "Hi'Ds platform is an unmatched tool to aware about the tourist destinations and its beauty. Tourism department's branding and marketing team is using AR VR platform to attract customers through out the world."
            }
          ]
        },
        {
          heading: "Food and Beverage",
          list: [
            {
              heading: "Catering Services",
              desc: "Catering service providers can use Hi'Ds AR VR platform to display their food and decorative products. This reality technology is creating vibe in customer's memory/"
            },
            {
              heading: "QSRs & FSRs",
              desc: "Menu display, party organizing verities and service facilities are the basic componets of QSR and FSRs. Hi'Ds AR VR technology provides the platform to showcase their product and services."
            },
            {
              heading: "Manufacturing Companies",
              desc: "Each and every local or international food and  beverages company advertise its products and services in large scale. Hi'Ds AR VR is a best and optimum utlize tool in this kind of branding and sales."
            }
          ]
        }
      ]
    },
    features: {
      title: "",
      list: [
        {
          title: "Platform as Service",
          desc: [
            "Hi'Ds AR VR platform is a tool for marketing, branding, daily operation, customer engagement, client's feedback and product presentation.",
            "Easy to navigate, visualization flexibility, quality and useful AR VR content makes us World's No 1 AR VR platform."
          ]
        },
        {
          title: "Access by Smartphone and VR Headset",
          desc: [
            "Hi'Ds AR VR platform is easy to access anywhere from any of the above device. AR contents can be access through smartphone. VR contents required a VR headset.",
            "Our platform is well designed by taking consideration of business needs. We have also taken care of content access flow. Which is helping every individual to access his/her content in just few clicks."
          ]
        },
        {
          title: "Product or Service Oriented Content",
          desc: [
            "Hi'Ds contents fit to your product line or service. Our content development team continuously updating and upgrading the content quantity and quality.",
            "Our product oriented platform fulfill all your business requirments flawlessly."
          ]
        }
      ]
    },
    additionalFeatures: {
      title: "",
      list: [
        {
          title: "Content on Demand",
          desc: [
            "Hi'Ds expertise is in preparing AR VR content as per industry, product or service line.",
            "Our pricing is competitive."
          ]
        },
        {
          title: "Digital Marketing Services",
          desc: [
            "Hi'Ds unique dashboard summarize and displays all navigations and learnings. This skill tracking facility is helping institutions and individuals to know about the potential growth.",
            "Corporates can utilize this facility during hiring, salary appraisal and promotions."
          ]
        },
        {
          title: "Feedback Facility",
          desc: [
            "With training and learning modules, Hi'Ds believes in periodic assessment of skill set. Our industry approach assessment mechanism, helps the learner to upgrade or update the skill or knowlegde.",
            "This assessment facility helps individuals and corporates to track the employees skill growth."
          ]
        }
      ]
    },
    meta: {
      title: "AR VR Implementation in Hospitality, Hotels & Tourism",
      desc: "Augmented Reality (AR) and Virtual reality (VR) Solutions in Hospitality, Tourism, Hotels, Tourist Places, Tour Guide, Sales, Branding and Marketing"
    }
  },
  {
    id: "ar-vr-mr-tailored-content-development-services",
    img: "tailored-service.png",
    bgColor: "#472d75",
    title: "AR/VR/MR Tailored Content Development Services",
    heading: "Hi'Ds provides tailored AR VR MR contents as per industry requirement with reasonable price. Our Technology Acumen + Your Insights = Revenue Growth!",
    subHeading: "Hi'Ds will serve you furnished AR/VR/MR contents as per your requirements within a minimal cost and time",
    list: [
      "Hi'Ds provids AR/VR/MR contents for all industries",
      "AR/VR/MR content upgradation services"
    ],
    shortDesc: [
      "An Individual or organization, manufacturer or service provider, K12 institution or university, we can develop tailored content for everyone.",
      "Our expert and professional team is capable of meeting deadlines and exceeding expectations.",
      "Interested in VR and AR, but don’t know where to start? Get connected with Hi'Ds for better content development and on budget guarantee."
    ],
    meta: {
      title: "AR VR MR Tailored Content Services by HiDs for All Industries",
      desc: "Augmented Reality (AR), Virtual reality (VR) and Mixed Reality (MR) Content Services for Manufacturing, Service, IT, Its Companies, Educational Institutions"
    }
  },
  {
    id: "ar-vr-solutions-for-interior-designers",
    img: "interior-designers.png",
    bgColor: "#516873",
    title: "AR/VR Solutions for Interior Designers",
    heading: "Design Your Interior by Just Drag & Drop. Experience the Output on Real Time. Get What You Designed as End Product.",
    subHeading: "Integrated leading interior brand's library helps to choose best product suits to your dream house or office. This design is a true copy of the end product. \"ZERO\" error.",
    list: [
      "Platform integrated with all leading brands",
      "Design & configure your house, kitchen, hall, office etc",
      "Experince the outcome before it builds",
      "100% satisfiction"
    ],
    shortDesc: [
      "A tool for Interior designers and a product for Interior hubs to experience the reality before it builds.",
      "Get fully access to Hi'Ds platfom, can be used for design to deliver.",
      "Hi'Ds VR service to meet organisation’s sales, marketing needs and customer satisfaction with a fully customizable taxonomy."
    ],
    meta: {
      title: "AR VR Solutions by HiDs for Interior Designers",
      desc: "Interior Designers, Builders Use Augmented Reality (AR) and Virtual reality (VR) to Showcase Interior Designs of Rooms, Kitchens, Offices, Shops and Showrooms"
    }
  },
  {
    id: "ar-vr-solutions-for-civil-and-construction",
    img: "civil-construction.png",
    bgColor: "#355a37",
    title: "AR/VR Solutions for Civil and Construction",
    heading: "Design & Configure All Civil and Construction Activities by Our Platform. Experience the Reality Before it Builds.",
    subHeading: "Hi'Ds virtual experience platform is the perfect tool for all civil and construction projects. Prospects can configure the project as per their requirment. 10X Sales guarrentee.",
    list: [
      "Platform integrated with all leading brands",
      "Design & configure house, kitchen, hall, office etc as per your need",
      "Experince the outcome before it builds",
      "10X Client conversation ration",
      "2D & 3D design support"
    ],
    shortDesc: [
      "For interior designers, builders, developers, contractors and real estate agents.",
      "Get customised design on new construction, modification & interior design.",
      "Get access to Hi'Ds’s virtual reality platform. As \"Seeing is Believing\", access, modify and feel what exactly you will get."
    ],
    meta: {
      title: "AR VR Solutions by HiDs for Civil and Construction",
      desc: "Builders, Developers and Real Estate Agents Use Augmented Reality (AR) and Virtual reality (VR) to Sales Flats, Apartments, Duplex, Plots, Offices, Shops and Showrooms"
    }
  }
]


export const homeBanner = [
  {
    id: 1,
    title: 'Civil & Construction',
    thumb: "https://placehold.it/210x116/00a5bd",
    banner: "https://placehold.it/1400x750/00a5bd"
  }, {
    id: 2,
    title: 'Interior Designing',
    thumb: "https://placehold.it/210x116/4f27a0",
    banner: "https://placehold.it/1400x750/4f27a0"
  }, {
    id: 3,
    title: 'Industrial & Educational Training',
    thumb: "https://placehold.it/210x116/37983b",
    banner: "https://placehold.it/1400x750/37983b"
  }, {
    id: 4,
    title: 'Internship Through VR',
    thumb: "https://placehold.it/210x116/e48200",
    banner: "https://placehold.it/1400x750/e48200"
  }, {
    id: 5,
    title: 'VR Tourism',
    thumb: "https://placehold.it/210x116/1881db",
    banner: "https://placehold.it/1400x750/1881db"
  }
];

export const blogs = [
  {
    id: 1,
    title: 'Civil & Construction',
    thumb: "https://placehold.it/250x150/00a5bd",
    author: "Jeni",
    readTime: 12
  },
  {
    id: 2,
    title: 'Interior Designing',
    thumb: "https://placehold.it/250x150/4f27a0",
    author: "Shon",
    readTime: 9
  },
  {
    id: 3,
    title: 'Industrial & Educational Training',
    thumb: "https://placehold.it/250x150/37983b",
    author: "John",
    readTime: 6
  }
];

export const faqs = [
  {
    id: 1,
    question: 'What is Augmented Reality (AR)?',
    answer: "Augmented Reality (AR) adds digital elements to a live view often by using the camera on a smartphone or tablet. AR-powered tablets are useful for classroom training, AR-powered wearables can assist while performing jobs on field."
  },
  {
    id: 2,
    question: 'What is Virtual Reality (VR)?',
    answer: "Virtual reality (VR) implies a complete immersion experience that disconnects from the real world. User can navigate with gestures and hand-controllers inside the virtual world."
  },
  {
    id: 3,
    question: 'What is Mixed Reality (MR)?',
    answer: "Mixed Reality (MR) is an interaction between real-world and digital objects. In another word, it can be said as combination of both AR and VR."
  },
  {
    id: 4,
    question: 'What is Extended Reality (XR)?',
    answer: "Extended Reality (XR) is not a separate technology, Augmented Reality (AR), Virtual Reality (VR) and  Mixed Reality (MR) are the variants of it."
  },
  {
    id: 5,
    question: 'How AR can be experienced?',
    answer: "Augmented Reality (AR) can be experienced by handheld and hands-free devices. Smartphones and tablets are handheld devices. Where as Head-Mounted Tablets and smart glasses are hands-free devices."
  },
  {
    id: 6,
    question: 'What are different types of VR devices?',
    answer: "Basically two types of VR devices are using world-wide. PC powered and Standalone VR headsets. Oculus Rift and HTC Vive comes under PC powered where as Oculus Quest and Vive Focus falls under standalone category."
  },
  {
    id: 7,
    question: 'Does a PC  necessary to power VR device for industrial training modules?',
    answer: "No, not exactly. If it is a PC powered VR headset, an external workstation is required. For standalone VR headset not required."
  },
  {
    id: 8,
    question: 'How can we trust Hi\'Ds while sharing sensitive information?',
    answer: "Hi'Ds is a dependable and trustworthy partner when it comes to respecting Intellectual Property (IP) and the custody of sensitive and confidential client information. We have handled and delivered many state and central government projects through NDAs with successful outcomes."
  },
  {
    id: 9,
    question: 'Is there any custom-designed hardware involved in Hi\'Ds solutions?',
    answer: "Hi'Ds all AR VR MR solutions are fit to all off-the-self hardware and devices available in the commercial market."
  },
  {
    id: 10,
    question: 'Can we have a demonstration developed before signing the actual project?',
    answer: "Yes, Hi'Ds is providing a demonstration before taking on the actual project. This is free of cost if subsequent project awarded to HiDs."
  },
  {
    id: 11,
    question: 'Why Should I partner with HiDs?',
    answer: "Hi'Ds team sum of engineers and industry experts who understand the pain-points & ground-reality of manufacturing, sales, marketing, branding and training departments of different industries.\n\n We speak the manufacturing language and understand the scope of work (SOW) to effectively and cost efficiently implement projects to overcome the challenges. \n\n “Testimonials” from our satisfied clients and partners are the living proofs of our relationship."
  }
];

export const profiles = [
  {
    id: "hids1",
    name: "Santosh Kumar Panda",
    img: "Santosh.jpg",
    position: "Founder & CEO",
    about: "Santosh is responsible for growth and strategy at HiDs. He plays a key role in developing long-term complimentary roadmaps which direct engineering decisions."
  },
  {
    id: "hids2",
    name: "Narendra Patro",
    img: "Narendra.jpg",
    position: "Director - Finance",
    about: "Narendra Patro manages day to day accounts and financial activities at HiDs. His key responsibilities include overseeing the daily accounts activities, monthly, quarterly and annual financial planning."
  }
]

// export const socials = [
//   {title:"Facebook", link:"https://www.facebook.com/HiDs-Technologies-101934508284870"},
//   {title:"LinkedIn", link:"https://www.linkedin.com/in/hids-technologies-pvt-ltd-8934901b3"},
//   {title:"Twitter", link:"https://twitter.com/HidsLimited"},
//   {title:"Instagram", link:"https://instagram.com/hidstechnologies"},
//   {title:"YouTube", link:"https://www.youtube.com/channel/UC1RthQmDIeFxmmkuwd2wzLg"}
// ]