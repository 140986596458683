import React from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';
import InstagramIcon from '@material-ui/icons/Instagram';
import Zoom from '@material-ui/core/Zoom';
import { solutions } from '../db';

const Footer = () => {
  return (
    <div className="footer">
       {/* <svg version="1.1" className="footer_header" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1440 126" xmlSpace="preserve">
       <path className="st0" d="M685.6,38.8C418.7-11.1,170.2,9.9,0,30v96h1440V30C1252.7,52.2,1010,99.4,685.6,38.8z" />
      </svg> */}
      <div className="upper_footer">
        <h3 className="title">Learn What Hi'Ds Can Do for You</h3>
        <div className="upper_footer_container">
          <div className="contact_us">
            <h4>Contact Us</h4>
            <h2>Let’s Get in Touch</h2>
            <p>Whether its about exploring a solution that transforms the way you do business, or setting up a chat over a cup of coffee —We listen.</p>
            <Button variant="contained" color="primary">Lets Talk</Button>
          </div>
          <div className="free_demo">
            <h4>Free Demo</h4>
            <h2>Watch a Demo</h2>
            <p>Get a free demo of Hi'Ds — the VR platform that helps your marketing & sales team to multiply the conversion ratio with one hundred percent customer satisfiction.</p>
            <Button variant="contained" color="primary">Watch Demo</Button>
          </div>
          <div className="business">
            <h4>Business</h4>
            <h2>Let’s Partner with Hi'Ds</h2>
            <p>Hi'Ds appointing "Channel Partners" city wise. Be a part of high potential income business model.</p>
            <Button variant="contained" color="primary">Explore Business Opportunity</Button>

          </div>
        </div>

      </div>
      <div className="middle_footer">
        <div className="about_hids">

          <img src={require('../images/logo.svg')} loading="lazy" className="footer_logo" title="Logo" alt="Logo"/>

          {/* <ul>
            <li><Link to="/about">About Us</Link></li>
          </ul> */}
        </div>
        <div className="hids_ftr_links">
          <h3 className="title">Hi'Ds</h3>
          <ul>
            <li> <Link to="/">Home</Link></li>
            <li> <Link to="/platform">Platform</Link></li>
            <li><Link to="/login">Login to Hi'Ds</Link></li>
            <li><Link to="/signup">Sign up for a Demo</Link></li>
            <li><Link to="/business-enquiry">Business Enquiry</Link></li>
          </ul>
        </div>
        <div className="solution_links">
          <h3 className="title">Solutions</h3>
          {solutions.length > 0 &&
            <ul>
              {solutions.map(sol =>
                <li key={sol.id}><Link to={`/solution/${sol.id}`} key={sol.id}>{sol.title}</Link></li>
              )}
            </ul>
          }
        </div>
        <div className="resources">
          <h3 className="title">Resources</h3>
          <ul>
            <li><Link to="/faqs">FAQs</Link></li>
            <li><Link to="/help-centre">Help Centre</Link></li>
          </ul>
        </div>
        <div className="company">
          <h3 className="title">Company</h3>
          <ul>
            <li><Link to="/about">About Us</Link></li>
            <li><Link to="/contactsales">Contact</Link></li>
          </ul>
        </div>
      </div>
      <div className="lower_footer">
        <div className="social_links">
          <Tooltip title="Facebook" placement="top" arrow TransitionComponent={Zoom}>
            <a href="https://www.facebook.com/HiDs-Technologies-101934508284870" target="_blank" rel="noopener noreferrer"><FacebookIcon /></a>
          </Tooltip>
          <Tooltip title="LinkedIn" placement="top" arrow TransitionComponent={Zoom}>
            <a href=" https://www.linkedin.com/company/hids-technologies" target="_blank" rel="noopener noreferrer"><LinkedInIcon /></a>
          </Tooltip>
          {/* <Tooltip title="Twitter" placement="top" arrow TransitionComponent={Zoom}>
            <a href="https://twitter.com/HidsLimited" target="_blank" rel="noopener noreferrer"><TwitterIcon /></a>
          </Tooltip> */}
          <Tooltip title="Instagram" placement="top" arrow TransitionComponent={Zoom}>
            <a href="https://instagram.com/hidstechnologies" target="_blank" rel="noopener noreferrer"><InstagramIcon /></a>
          </Tooltip>
          <Tooltip title="YouTube" placement="top" arrow TransitionComponent={Zoom}>
            <a href="https://www.youtube.com/channel/UC1RthQmDIeFxmmkuwd2wzLg" target="_blank" rel="noopener noreferrer"><YouTubeIcon /></a>
          </Tooltip>
        </div>
        <div className="copy_writes">
          <p>Copyright © 2020 Hi'Ds Technologies Pvt Ltd.  All Rights Reserved</p>
        </div>
        <div className="other_links">
          <ul>
            <li><Link to="/terms-and-conditions">Terms and Conditions</Link></li>
            <li><Link to="/privacy-policy">Privacy Policy</Link></li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Footer;
