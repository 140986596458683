import React from 'react'

const SolutionBanner = ({data}) => {
  return (
    <div style={{backgroundColor:data.bgColor ? data.bgColor : "#eee"}} className="solution_banner">
      <div className="info">
        <h3 className="title">{data.title}</h3>
        <h1 className="heading">{data.heading}</h1>
        <h2 className="sub_heading">{data.subHeading}</h2>
      </div>
      <div className="img">
      {data.img && <img src={require(`../images/solutions/${data.img}`)} loading="lazy" alt={data.title} title={data.title} />}
      </div>
    </div>
  )
}

export default SolutionBanner;
