import React from 'react'

const KeyBenefits = ({ keyBenefits }) => {
  // console.log('data====', keyBenefits)

  return (
    <div>
      <div className="key_benifits_container">
        <h2>Key Benefits</h2>
        <div className="key_benifits">
          {keyBenefits.length > 0 && keyBenefits.map((kb, i) =>
            <div key={i} className="benifts_card">
              <h2 className="title">{kb.title}</h2>
              {kb.desc && kb.desc.length > 0 &&
                kb.desc.map((d, j) =>
                  <p key={i + j} className="desc">{d}</p>
                )
              }
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default KeyBenefits
