import React, { useState } from "react";
import { MainContent } from "../components";
import { useLocation } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import axios from "axios";
import Swal from "sweetalert2";
import CircularProgress from "@material-ui/core/CircularProgress";

const Career = () => {
  // window.scrollTo({ top: 0 });
  const location = useLocation();

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [qualification, setQualifications] = useState("");
  const [specialization, setSpecialization] = useState("");
  // const [softwareKnowledge, setSoftwareKnowledge] = useState("");
  const [expertiseLevel, setExpertiseLevel] = useState("");
  const [experience, setExperience] = useState("");
  const [organization, setOrganization] = useState("");
  const [pincode, setPincode] = useState("");
  const [appliedFor, setAppliedFor] = useState("");
  const [domain, setDomain] = useState("");
  const [course, setCourse] = useState("");
  const [mode, setMode] = useState("");
  const [loading, setLoading] = useState(false);

  const qualifications = [
    { name: "Intermediate", value: "Intermediate" },
    { name: "BA", value: "BA" },
    { name: "B.sc", value: "B.sc" },
    { name: "M.sc", value: "M.sc" },
    { name: "B.com", value: "B.com" },
    { name: "M.com", value: "M.com" },
    { name: "ITI", value: "ITI" },
    { name: "Diploma", value: "Diploma" },
    { name: "B.Tech", value: "B.Tech" },
    { name: "M.Tech", value: "M.Tech" },
    { name: "B.Pharma", value: "B.Pharma" },
    { name: "M.Pharma", value: "M.Pharma" },
  ];

  // "node-sass": "^4.14.1",
  // const softwareKnowledges = [
  //   { name: "3dsMax", value: "3dsMax" },
  //   { name: "Ansys", value: "Ansys" },
  //   { name: "AutoCAD", value: "AutoCAD" },
  //   { name: "Blender", value: "Blender" },
  //   { name: "Catia", value: "Catia" },
  //   { name: "Creo", value: "Creo" },
  //   { name: "Maya", value: "Maya" },
  //   { name: "NX", value: "NX" },
  //   { name: "Revit", value: "Revit" },
  //   { name: "StaadPro", value: "StaadPro" },
  //   { name: "Unity", value: "Unity" },
  // ];
  const expertiseLevels = [
    { name: "Beginner", value: "Beginner" },
    { name: "Intermediate", value: "Intermediate" },
    { name: "Advance", value: "Advance" },
    { name: "Master", value: "Master" },
  ];
  const experiences = [
    { name: "0", value: "0" },
    { name: "1", value: "1" },
    { name: "2", value: "2" },
    { name: "3", value: "3" },
    { name: "4", value: "4" },
    { name: "Above 5", value: "5" },
  ];

  const APPLIEDFOR = [
    { name: "Internship", value: "internshp" },
    { name: "Full Time Job", value: "full_time_job" },
    {
      name: "Short Term Certified Course",
      value: "short_term_certified_course",
    },
  ];
  const DOMAIN = [
    { name: "AR/VR Developer", value: "ar_vr_developer" },
    { name: "Fullstack Developer (MERN)", value: "fullstack_developer_mern" },
    { name: "Flutter Developer", value: "flutter_developer" },
    { name: "3D Designer", value: "3d_designer" },
    { name: "Marketing & sales", value: "marketing_and_sales" },
    { name: "Content Writing", value: "content_writing" },
  ];

  const COURSE = [
    { name: "AR/VR Beginner (1 Month)", value: "ar_vr_beginner_1_month" },
    { name: "AR/VR Master (3 Month)", value: "ar_vr_master_3_month" },
    { name: "AR/VR Pro (6 Month)", value: "ar_vr_pro_6_month" },
  ];

  const MODE = [
    { name: "Online", value: "online" },
    { name: "Offline", value: "offline" },
  ];

  const onSubmitHandle = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      const response = await axios.post(
        "https://api.hilearner.in/api/v1/hidstech/career",
        {
          name,
          phone,
          email,
          qualification,
          specialization,
          // softwareKnowledge,
          expertiseLevel,
          experience,
          organization,
          pincode,
          appliedFor,
          domain,
          course,
          mode,
        }
      );


        console.log('response======>', response)

      if (response.status === '400' && response.message) { }
      if (response) {
        setLoading(false);
        Swal.fire("Good job!", "Successfully Saved!", "success").then(
          result=> {
            setName("");
            setPhone("");
            setEmail("");
            setQualifications("");
            setSpecialization("");
            // setSoftwareKnowledge("");
            setExpertiseLevel("");
            setExperience("");
            setOrganization("");
            setPincode("");
            setAppliedFor("");
            setDomain("");
            setCourse("");
            setMode("");
          }
        );
      }
    } catch (error) {
      setLoading(false);
      if (error.response) {
        // Request made and server responded
        // console.log(error.response.data);
        // console.log(error.response.status);
        // console.log(error.response.headers);

        if (error.response.status === 400 && error.response.data) { 
          return Swal.fire({
            icon: 'error',
            html: error?.response?.data?.message.split(",").join("<br /><br />") || "Something went wrong!"
          }) 
        }
        if (error.response.status === 429) { 
          return Swal.fire({
            icon: 'error',
            text: 'Please try after some time !!'
          }) 
        }

      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
      }
    } 
  };

  return (
    <MainContent
      minHeight="80vh"
      noPadding
      path={location.pathname}
      title="Career"
      desc="career"
    >
      <div className="career">
        <div className="career_card">
          <form onSubmit={onSubmitHandle}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="name"
              name="name"
              label="Name"
              autoFocus
              onChange={(e) => setName(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="phone"
              name="phone"
              label="Contact Number"
              onChange={(e) => setPhone(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              name="email"
              label="Email Address"
              onChange={(e) => setEmail(e.target.value)}
            />
            <FormControl variant="outlined" margin="normal" fullWidth>
              <InputLabel id="qualification-label">Qualification</InputLabel>
              <Select
                labelId="qualification-label"
                id="qualification"
                value={qualification}
                onChange={(e) => setQualifications(e.target.value)}
                label="Qualification"
              >
                {qualifications.map((q, i) => (
                  <MenuItem value={q.value} key={i}>
                    {q.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="specialization"
              name="specialization"
              label="Skills & Tools"
              onChange={(e) => setSpecialization(e.target.value)}
            />
            {/* <FormControl
              variant="outlined"
              variant="outlined"
              margin="normal"
              fullWidth
            >
              <InputLabel id="software-knowledges-label">
                Software Knowledge
              </InputLabel>
              <Select
                labelId="software-knowledges-label"
                id="software-knowledges"
                value={softwareKnowledge}
                onChange={(e) => setSoftwareKnowledge(e.target.value)}
                label="Software Knowledge"
              >
                {softwareKnowledges.map((q, i) => (
                  <MenuItem value={q.value} key={i}>
                    {q.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}

            {/* Appied for */}
            <FormControl variant="outlined" margin="normal" fullWidth>
              <InputLabel id="software-knowledges-label">
                Applying For
              </InputLabel>
              <Select
                labelId="applied-for-label"
                id="applied-for"
                value={appliedFor}
                onChange={(e) => setAppliedFor(e.target.value)}
                label="Applied For"
              >
                {APPLIEDFOR.map((q, i) => (
                  <MenuItem value={q.value} key={i}>
                    {q.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {/* Appied for Domain */}
            {["internshp", "full_time_job"].includes(appliedFor) && (
              <FormControl variant="outlined" margin="normal" fullWidth>
                <InputLabel id="domain-label">Domain</InputLabel>
                <Select
                  labelId="domain-label"
                  id="domain"
                  value={domain}
                  onChange={(e) => setDomain(e.target.value)}
                  label="Domain"
                >
                  {DOMAIN.map((q, i) => (
                    <MenuItem value={q.value} key={i}>
                      {q.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}

            {/* course choosen */}
            {appliedFor === "short_term_certified_course" && (
              <FormControl variant="outlined" margin="normal" fullWidth>
                <InputLabel id="course-label">Course</InputLabel>
                <Select
                  labelId="course-label"
                  id="course"
                  value={course}
                  onChange={(e) => setCourse(e.target.value)}
                  label="Course"
                >
                  {COURSE.map((q, i) => (
                    <MenuItem value={q.value} key={i}>
                      {q.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {/* course mode */}
            {appliedFor === "short_term_certified_course" && (
              <FormControl variant="outlined" margin="normal" fullWidth>
                <InputLabel id="mode-label">Mode</InputLabel>
                <Select
                  labelId="mode-label"
                  id="mode"
                  value={mode}
                  onChange={(e) => setMode(e.target.value)}
                  label="Mode"
                >
                  {MODE.map((q, i) => (
                    <MenuItem value={q.value} key={i}>
                      {q.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}

            <FormControl variant="outlined" margin="normal" fullWidth>
              <InputLabel id="expertiseLevel-label">Expertise Level</InputLabel>
              <Select
                labelId="expertiseLevel-label"
                id="expertiseLevel"
                value={expertiseLevel}
                onChange={(e) => setExpertiseLevel(e.target.value)}
                label="Expertise Level"
              >
                {expertiseLevels.map((q, i) => (
                  <MenuItem value={q.value} key={i}>
                    {q.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl variant="outlined" margin="normal" fullWidth>
              <InputLabel id="experience-label">Experience</InputLabel>
              <Select
                labelId="experience-label"
                id="experience"
                value={experience}
                onChange={(e) => setExperience(e.target.value)}
                label="Experience"
              >
                {experiences.map((q, i) => (
                  <MenuItem value={q.value} key={i}>
                    {q.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="organization"
              name="organization"
              label="Institution/Company Name"
              onChange={(e) => setOrganization(e.target.value)}
            />

            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="pincode"
              name="pincode"
              label="Location PIN"
              onChange={(e) => setPincode(e.target.value)}
            />
            <br />
            <br />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className="hids_button"
            >
              {loading ? (
                <CircularProgress color="#f4af1b" size={24} />
              ) : (
                "Apply"
              )}{" "}
            </Button>
          </form>
        </div>
      </div>
    </MainContent>
  );
};

export default Career;