import React, { useEffect, useState } from 'react'
import { Hero, Blogs, MainContent, AboutSec, HomeBanner, SolutionSec } from '../components';
import WelcomePopup from '../components/WelcomePopup';


const Home = () => {
  window.scrollTo({ top: 0 });

  const [showWelcomePopup, setshowWelcomePopup] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setshowWelcomePopup(true)
    }, 1000)
  }, [])
  
  return (
    <MainContent
      noPadding
      title="AR VR MR Solutions from HiDs for Studies, Trainings, Sales and Marketing"
      desc="Augmented Reality (AR), Virtual reality (VR) and Mixed Reality (MR) for School, College Studies, Industrai Trainings, Sales and Marketing, Advertisement, Tourism"
    >
      {/* <HomeBanner/> */}
      <Hero />
      <AboutSec />
      {/* <Blogs/> */}
      <svg version="1.1" className="solution_header" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1440 126" xmlSpace="preserve">
        <path className="st0" d="M685.6,38.8C418.7-11.1,170.2,9.9,0,30v96h1440V30C1252.7,52.2,1010,99.4,685.6,38.8z" />
      </svg>
      <SolutionSec />
      <WelcomePopup show={showWelcomePopup} closePopup={() => setshowWelcomePopup(false)}/>
    </MainContent>
  )
}

export default Home;
