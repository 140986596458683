import React from 'react';
import { useLocation } from "react-router-dom";
import { MainContent, ComingSoon } from '../components';


const HelpCentre = () => {
  window.scrollTo({ top: 0 });
  const location = useLocation();
  return (
    <MainContent noPadding path={location.pathname} title="Help Centre" desc="Help Centre" minHeight="80vh">
      <div className="help_center">
        <ComingSoon />
      </div>
    </MainContent>
  )
}

export default HelpCentre
