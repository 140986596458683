import React from 'react'

const ComingSoon = () =>{
  return (
    <div className="comming_soon">
      {/* <h1>Coming Soon</h1> */}
      <img src="https://www.justphotography.nz/wp-content/uploads/Under-development.png" loading="lazy" alt="Under Development"  title="Under Development"/>
    </div>
  )
}

export default ComingSoon
