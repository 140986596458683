import React from 'react'
import { NavLink } from 'react-router-dom'
import { solutions } from '../db';

const Menu = () => {
  return (
    <div className="header_menu">
      <ul className="menu_list">
        <li> Solutions
          {solutions.length > 0 &&
            <div className="sub_menu">
              <ul>
                {solutions.map(sol =>
                  <li key={sol.id} className="submenu_list_container">
                    <NavLink to={`/solution/${sol.id}`} key={sol.id}>
                      <h5 className="submenu_title">{sol.title}</h5>
                      {sol.list.length > 0 &&
                        <ul>
                          {sol.list.map((lst, i) =>
                            <li key={i} className="submenu_list">{lst}</li>
                          )}
                        </ul>
                      }
                    </NavLink>
                  </li>
                )}
              </ul>
            </div>
          }
        </li>
        <li><NavLink to="/platform">Platform</NavLink></li>
        <li><NavLink to="/demo">Demo</NavLink></li>
        <li><NavLink to="/faqs">Faqs</NavLink></li>
        <li><a href="https://hilearner.in/login">Login</a></li>
        <li><NavLink to="/contactsales">Contact Sales</NavLink></li>
        <li><NavLink to="/career">Career</NavLink></li>
        {/* <li><NavLink to="/trynow">Try Now</NavLink></li> */}
      </ul>
    </div>
  )
}

export default Menu;
