import React from 'react';
import { useLocation } from "react-router-dom";
import { MainContent, ComingSoon } from '../components';


const SignUp = () => {
  window.scrollTo({ top: 0 });
  const location = useLocation();
  return (
    <MainContent noPadding path={location.pathname} title="SignUp" desc="SignUp" minHeight="80vh">
      <div className="sign_up">
        <ComingSoon />
      </div>
    </MainContent>
  )
}

export default SignUp
