import Home from "./pages/Home";
import Solutions from "./pages/Solutions";
import Platform from "./pages/Platform";
import Demo from "./pages/Demo";
import Faqs from "./pages/Faqs";
import Login from "./pages/Login";
import ContactSales from "./pages/ContactSales";
import Career from "./pages/Career";
import AboutUs from "./pages/AboutUs";
import BusinessEnquiry from "./pages/BusinessEnquiry";
import HelpCentre from "./pages/HelpCentre";
import TermsAndConditions from "./pages/TermsAndConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import SignUp from "./pages/SignUp";


export const routes = [
  { id: 1, path: "/", name: "home", Component: Home },
  { id: 2, path: "/solution/:id", name: "Solutions", Component: Solutions },
  { id: 3, path: "/platform", name: "Platform", Component: Platform },
  { id: 4, path: "/demo", name: "Demo", Component: Demo },
  { id: 5, path: "/faqs", name: "Faqs", Component: Faqs },
  { id: 6, path: "/login", name: "Login", Component: Login },
  { id: 7, path: "/contactsales", name: "ContactSales", Component: ContactSales },
  { id: 8, path: "/career", name: "Career", Component: Career },
  { id: 9, path: "/about", name: "About Us", Component: AboutUs },
  { id: 10, path: "/business-enquiry", name: "Business Enquiry", Component: BusinessEnquiry },
  { id: 11, path: "/help-centre", name: "Help Centre", Component: HelpCentre },
  { id: 12, path: "/terms-and-conditions", name: "Terms And Conditions", Component: TermsAndConditions },
  { id: 13, path: "/privacy-policy", name: "Privacy Policy", Component: PrivacyPolicy },
  { id: 14, path: "/signup", name: "SignUp", Component: SignUp }
];