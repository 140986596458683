import React from 'react';
import { useLocation } from "react-router-dom";
import { MainContent, ComingSoon } from '../components';


const BusinessEnquiry = () => {
  window.scrollTo({ top: 0 });
  const location = useLocation();
  return (
    <MainContent noPadding path={location.pathname} title="Business Enquiry" desc="Business Enquiry" minHeight="80vh">
      <div className="business_enquiry">
        <ComingSoon />
      </div>
    </MainContent>
  )
}

export default BusinessEnquiry
