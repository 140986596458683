import React from 'react'
import { Link } from 'react-router-dom'

const SolutionCard = ({data}) =>{
  return (
    <div className="sloution_card">
    <div className="meta" style={{backgroundColor:data.bgColor ? data.bgColor : "#eee"}}>
      {data.img && <img src={require(`../images/solutions/${data.img}`)} loading="lazy" alt={data.title} title={data.title}/>}
      <h2 className="title">{data.title}</h2>
    </div>
    <div className="description">
      {data.shortDesc && data.shortDesc.length > 0 && data.shortDesc.map((sd,i)=>
          <p className="desc" key={data.title+i}>{sd}</p>
      )}     
    </div>
    <p className="read-more">
        <Link to={`/solution/${data.id}`}>Learn More</Link>
      </p>
  </div>
  )
}

export default SolutionCard
