import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useLocation } from "react-router-dom";
import { MainContent } from '../components';
import { faqs } from '../db';

const Faqs = () => {
  window.scrollTo({ top: 0 });
  const location = useLocation();

  return (
    <MainContent minHeight="80vh" noPadding path={location.pathname} title="AR VR MR Frequently Asked Questions (FaQs)" desc="What can be Done by Augmented Reality (AR), Virtual reality (VR) and Mixed Reality (MR). Its Benefits.">
      <div className="faqs">
        <div className="faqs_jumbotron">
          <h1 className="heading">Frequently Asked Questions</h1>
          <p className="sub_heading">Answers to frequently asked questions about Hi'Ds AR/VR/MR solutions and platform</p>
        </div>

        <div className="faq_container">
          {faqs.length > 0 && faqs.map(faq =>
            <Accordion key={faq.id}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>{faq.question}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className="answer">
                  {faq.answer}
                </Typography>
              </AccordionDetails>
            </Accordion>
          )}
        </div>

      </div>
    </MainContent>
  )
}

export default Faqs;
