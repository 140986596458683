import React from "react";
import { Header, Footer } from "./components";
import Context from "./context";

const Layout = props => {
  return (
    <Context.Consumer>
      {context => (
        <div className="hids_app">
          <Header />
          <div className="hids_container">
            {props.children}
          </div>
          <Footer />
        </div>
      )}
    </Context.Consumer>
  );
};

export default Layout;
