import React, { useEffect, useState } from 'react';
import { solutions } from '../db'
import { useParams, useLocation } from 'react-router-dom';
import { BrandStrap, MainContent, SolutionBanner, KeyBenefits, UseCases, Features, AdditionalFeatures } from '../components';

const Solutions = () => {
  const { id } = useParams();
  const [data, setData] = useState({})
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0 });
    const res = solutions.filter(sol => sol.id === id);
    setData(res[0]);
  }, [id])


  return (
    <MainContent noPadding title={data?.meta?.title} desc={data?.meta?.desc} path={location.pathname}>
      <SolutionBanner data={data} />
      {/* <BrandStrap/> */}
      {data?.keyBenefits && <KeyBenefits keyBenefits={data.keyBenefits} />}
      {data?.useCases && data.useCases !== {} && <UseCases useCases={data.useCases} />}
      {data?.features && data.features !== {} && <Features features={data.features} />}
      {data?.additionalFeatures && data.additionalFeatures !== {} && <AdditionalFeatures additionalFeatures={data.additionalFeatures} />}
    </MainContent>
  )
}

export default Solutions;
