import React from 'react'

const AdditionalFeatures = ({ additionalFeatures }) => {
  return (
    <div>
      <div className="additional_features_container">
        <div className="left">
          <h2>Additional Features</h2>
        </div>
        <div className="additional_features">
          {additionalFeatures.list.length > 0 && additionalFeatures.list.map((af, i) =>
            <div key={i} className="additional_features_card">
              <h2 className="title">{af.title}</h2>
              {af.desc && af.desc.length > 0 &&
                af.desc.map((f, j) =>
                  <p key={i + j} className="desc">{f}</p>
                )
              }
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default AdditionalFeatures
