import React, { useEffect, useState } from 'react';
import Menu from './Menu';
import { NavLink } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { solutions } from '../db';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';


const Header = () => {

  const [header, setHeader] = useState("header")
  const [mobileMenu, setMobileMenu] = useState(false)
  const [open, setOpen] = React.useState(false);

  const listenScrollEvent = (event) => {
    if (window.scrollY < 70) {
      return setHeader("header")
    } else if (window.scrollY > 73) {
      return setHeader("header fill")
    }
  }

  const toggleDrawer = (e) => {
    if (e && e.type === 'keydown' && (e.key === 'Tab' || e.key === 'Shift')) {
      return;
    }
    setMobileMenu(!mobileMenu)
  };

  const handleClick = () => {
    setOpen(!open);
  };

  useEffect(() => {
    window.addEventListener('scroll', listenScrollEvent);
    return () =>
      window.removeEventListener('scroll', listenScrollEvent);
  }, []);

  return (
    <div className={header}>
      <div className="header_left">
        <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer} className="mobile_menu_button">
          <MenuIcon />
        </IconButton>

        <NavLink to="/">
          <img src={require('../images/logo.svg')} loading="lazy" className="logo" alt="logo" title="logo" />
        </NavLink>
      </div>
      <div className="header_right">
        <div className="header_info">
        </div>
        <Menu />
      </div>


      <SwipeableDrawer anchor='left'
        open={mobileMenu}
        onClose={toggleDrawer}
        onOpen={toggleDrawer}
        className="mobile_menu"
      >
       <div className="drawer_header">
       <NavLink to="/" onClick={toggleDrawer}>
          <img src={require('../images/logo.svg')} loading="lazy" className="logo" alt="logo" title="logo" />
        </NavLink>
       <IconButton onClick={toggleDrawer}><ChevronLeftIcon /></IconButton>
       </div>

        <List component="nav"  aria-labelledby="Mobile Menu" >
        <ListItem button onClick={toggleDrawer}><NavLink to="/about" className="menu_itms">About HIDS</NavLink></ListItem>
          <ListItem button onClick={handleClick}>
            <ListItemText primary="Solutions" className="menu_itms" />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="ul" disablePadding className="sub_menu_list">
              {solutions.map(sol =>
                <li key={sol.id} >
                  <ListItem button onClick={toggleDrawer}>
                    <NavLink to={`/solution/${sol.id}`} key={sol.id}>
                      <div>{sol.title}</div>
                    </NavLink>
                  </ListItem>
                </li>
              )}
            </List>
          </Collapse>
          <ListItem button onClick={toggleDrawer}><NavLink to="/platform" className="menu_itms">Platform</NavLink></ListItem>
          <ListItem button onClick={toggleDrawer}><NavLink to="/demo" className="menu_itms">Demo</NavLink></ListItem>
          <ListItem button onClick={toggleDrawer}><NavLink to="/faqs" className="menu_itms">Faqs</NavLink></ListItem>
          <ListItem button onClick={toggleDrawer}><a href="https://hilearner.in/login">Login</a></ListItem>
          <ListItem button onClick={toggleDrawer}><NavLink to="/contactsales" className="menu_itms">Contact Sales</NavLink></ListItem>
        </List>
      </SwipeableDrawer>

    </div>
  )
}

export default Header;
