import React from 'react';
// import CountUp from 'react-countup';
// import ArrowRightIcon from '@material-ui/icons/ArrowRight';

const AboutSec = () => {
  return (
    <>
    <div className="about_sec">
      <div className="left">
        <ul>
          <li>World's Largest Augmented Reality (AR) & Virtual reality (VR) Content Bank.</li>
          <li>Trusted by <span className="count_up">14</span> Government & Private Educational Institutions. </li>
          <li>Industrial Training Partner with <span className="count_up">11</span> Corporate Houses. </li>
          <li>Crossed <span className="count_up">2000</span> Users Per Day in Just <span className="count_up">4</span> Months Tenure.</li>
        </ul>
      </div>
      <div className="right">
        <div className="info client">
          <h4 className="title">VR LAB SETUP</h4>
          <img src={`${require('../images/icons/100x100-VR-Lab-Setup.png')}`} loading="lazy" alt="Lab Setup" title="Lab Setup" />
          <span className="count">28+</span>
        </div>
        <div className="info channel">
          <h4 className="title">EDUCATIONAL INSTITUTIONS SERVED</h4>
          <img src={`${require('../images/icons/100x100-Educational-Institute-Served.png')}`} loading="lazy" alt="Institute Served" title="Institute Served" />
          <span className="count">14</span>
        </div>
        <div className="info partners">
          <h4 className="title">INTERNSHIP PROVIDED</h4>
          <img src={`${require('../images/icons/100x100-Internship-Provided.png')}`} loading="lazy" alt="Internship Provided" title="Internship Provided" />
          <span className="count">964+</span>
        </div>
        <div className="info builder">
          <h4 className="title">PER DAY USERS</h4>
          <img src={`${require('../images/icons/100x100-Per-Day-Users.png')}`} loading="lazy" alt="Per Day Users" title="Per Day Users"/>
          <span className="count">2085+</span>
        </div>

        <div className="info partners">
          <h4 className="title">INTERIOR DESIGNORS SERVED</h4>
          <img src={`${require('../images/icons/100x100-Interior-Designer-Served.png')}`} loading="lazy" alt="Interior Designer Served" title="Interior Designer Served" />
          <span className="count">69</span>
        </div>

        <div className="info partners">
          <h4 className="title">CONTENT BANK</h4>
          <img src={`${require('../images/icons/100x100-Content-Bank.png')}`} loading="lazy" alt="Content Bank" title="Content Bank" />
          <span className="count">5000+</span>
        </div>
      </div>
    </div>
    </>
  )
}

export default AboutSec
