import React from 'react'

const Features = ({features}) =>{
  // console.log('features====>', features )
  return (
    <div>
      <div className="features_container">
        <div className="left">
        <h2>Features</h2>
        </div>
       <div className="features">
        {features.list.length > 0 && features.list.map((feature,i)=>
            <div key={i} className="features_card">
              <h2 className="title">{feature.title}</h2>
              {feature.desc && feature.desc.length > 0 &&
                feature.desc.map((f,j)=>
                  <p key={i+j} className="desc">{f}</p>
                )
              }
            </div>
          )}
       </div>
      </div>
    </div>
  )
}

export default Features
