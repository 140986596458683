import React from 'react'

const BrandStrap = () => {
  return (
    <div className="brand_strap">
      <div className="brand">
          <img src="https://placehold.it/150x100" alt=""/>
      </div>
      <div className="brand">
          <img src="https://placehold.it/150x100" alt=""/>
      </div>
      <div className="brand">
          <img src="https://placehold.it/150x100" alt=""/>
      </div>
      <div className="brand">
          <img src="https://placehold.it/150x100" alt=""/>
      </div>
      <div className="brand">
          <img src="https://placehold.it/150x100" alt=""/>
      </div>
      <div className="brand">
          <img src="https://placehold.it/150x100" alt=""/>
      </div>
      <div className="brand">
          <img src="https://placehold.it/150x100" alt=""/>
      </div>
      <div className="brand">
          <img src="https://placehold.it/150x100" alt=""/>
      </div>
      <div className="brand">
          <img src="https://placehold.it/150x100" alt=""/>
      </div>
    </div>
  )
}

export default BrandStrap;
