import React from 'react'
import { MainContent } from '../components';
import ComingSoon from '../components/ComingSoon';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useLocation } from "react-router-dom";


const ContactSales = () => {
  window.scrollTo({ top: 0 });
  const location = useLocation();

  return (
    <MainContent minHeight="80vh" noPadding path={location.pathname} title="AR VR MR Solutions by HiDs" desc="HiDs Provides Augmented Reality (AR), Virtual reality (VR) and Mixed Reality (MR) Content Solutions For All Industries and Departments">
      <div className="contact">

        <section className="location_section">
          <div className="title">

          </div>
          {/* <div className="locations">
            <div className="location_card">
              <img src="https://www.outlookindia.com/traveller/wp-content/uploads/2017/09/Bhubneshwar3_FI-640x360.jpg" alt=""/>
              <h3>Odisha, India</h3>
              <p>Our US support team is located across Odisha. They provide live support throughout the working day, ensuring that every customer, in every time zone, gets the highest level of service.</p>
              <address>
                Flat No 208,<br/> The Beaming Castle,<br/> Ranasinghpur,<br/> Bhubaneswar <br/> 751019
              </address>
            </div>

            <div className="location_card">
              <img src="https://www.outlookindia.com/traveller/wp-content/uploads/2017/09/Bhubneshwar3_FI-640x360.jpg" alt=""/>
              <h3>Odisha, India</h3>
              <p>Our US support team is located across Odisha. They provide live support throughout the working day, ensuring that every customer, in every time zone, gets the highest level of service.</p>
              <address>
                Flat No 208,<br/> The Beaming Castle,<br/> Ranasinghpur,<br/> Bhubaneswar <br/> 751019
              </address>
            </div>

            <div className="location_card">
              <img src="https://www.outlookindia.com/traveller/wp-content/uploads/2017/09/Bhubneshwar3_FI-640x360.jpg" alt=""/>
              <h3>Odisha, India</h3>
              <p>Our US support team is located across Odisha. They provide live support throughout the working day, ensuring that every customer, in every time zone, gets the highest level of service.</p>
              <address>
                Flat No 208,<br/> The Beaming Castle,<br/> Ranasinghpur,<br/> Bhubaneswar <br/> 751019
              </address>
            </div>
          </div> */}
        </section>

        <section className="contact_section">
          {/* <h1>Please get in touch and our expert support team will answer all your questions.</h1> */}

          <div className="contact_wrapper">
            {/* <div className="contact_form">
            <form>
              <TextField variant="outlined" margin="normal" required fullWidth id="email" label="Email Address" name="email" autoComplete="email" autoFocus />
              <TextField variant="outlined" margin="normal" required fullWidth id="name" label="Name" name="name" autoComplete="name" />
              <TextField variant="outlined" margin="normal" required fullWidth id="company" label="Company Name" name="company" autoComplete="company" />
              <TextField variant="outlined" margin="normal" required fullWidth id="phone" label="Phone Number" name="phone" autoComplete="phone" />

              <Button type="submit" fullWidth variant="contained" color="primary" className="hids_button"> Sign In </Button>
            </form>
          </div> */}

            <div className="team">
              <div className="team_list">
                <div className="person">
                  <img src={require('../images/profiles/Santosh.jpg')} loading="lazy" alt="Santosh" title="Sanosh" />
                  <div className="designation">Partnerships and Alliances</div>
                  <div className="name">Santosh Kumar Panda</div>
                  <div className="email">santosh.panda@hidstech.com</div>
                  <div className="contact_number">91 961 926 9264</div>
                </div>

                {/* <div className="person">
                <img src="https://placehold.it/100" alt="" />
                <div className="designation">Partnerships and Alliances</div>
                <div className="name">Santosh Kumar Panda</div>
                <div className="email">santosh.panda@hidstech.com</div>
                <div className="contact_number">91 961 926 9264</div>
              </div>

              <div className="person">
                <img src="https://placehold.it/100" alt="" />
                <div className="designation">Partnerships and Alliances</div>
                <div className="name">Santosh Kumar Panda</div>
                <div className="email">santosh.panda@hidstech.com</div>
                <div className="contact_number">91 961 926 9264</div>
              </div> */}
              </div>
            </div>
          </div>
        </section>

      </div>
    </MainContent>
  )
}

export default ContactSales;
