import React from 'react'
import { MainContent } from '../components';
import { profiles } from '../db';
import { useLocation } from "react-router-dom";

const AboutUs = () => {
  window.scrollTo({ top: 0 });
  const location = useLocation();

  return (
    <MainContent noPadding path={location.pathname} title="About HIDS" desc="" minHeight="80vh">
      <div className="about_us">
        <div className="info">
          <h1>We are HiDs</h1>
          <p>Our mission is what drives us to do everything possible virtually with reality. We do that by creating quality Augmented Reality (AR), Virtual Reality (VR) and Mixed Reality (MR) contents, by making our products easily accessable, by building a creative and diverse team and by making a positive impact in education, skill training, real estate, hospitality and many more industries.</p>
          <p>We not only make the world’s best AR VR MR contents, but through training and sustainable job creation, we empower technical and non-technical students and their families to break the cycle of poverty and build a brighter future.</p>
          <p>HiDs offers learning, training, branding, marketing, advertising and sales solutions by providing customized, worry-free, cost-effective, trainer less and time-saving AR VR MR contents for students, educational institutions, builders, promoters, real estate agents, hosiptality, tourism and many more– building thoughtful solutions based on our industries’ unique needs.</p>
          <p>Based in Bhubaneswar, Odisha, India, HiDs Technologies Pvt Ltd. serves the world.</p>
        </div>
        <svg version="1.1" className="leadership_header" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1440 126" xmlSpace="preserve">
          <path className="st0" d="M685.6,38.8C418.7-11.1,170.2,9.9,0,30v96h1440V30C1252.7,52.2,1010,99.4,685.6,38.8z" />
        </svg>
        <div className="leadership">
          <h2 className="heading">Meet Our Leadership</h2>
          <div className="profile_lists">
            {profiles && profiles.length > 0 && profiles.map(p =>
              <figure className="profile_card">
                <figcaption>
                  <h2>{p.name}</h2>
                  <p>{p.about}</p>
                  {/* <div className="icons"><a href="#"><i className="ion-ios-home"></i></a><a href="#"><i className="ion-ios-email"></i></a><a href="#"><i className="ion-ios-telephone"></i></a></div> */}
                </figcaption><img src={require(`../images/profiles/${p.img}`)} loading="lazy" alt={p.name} title={p.name} />
                <div className="position">{p.position}</div>
              </figure>
            )}
          </div>
        </div>
      </div>
    </MainContent>
  )
}

export default AboutUs
