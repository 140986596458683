import React from 'react'
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { MainContent } from '../components';

const Login = () => {
  window.scrollTo({ top: 0 });

  return (
    <MainContent minHeight="80vh" noPadding path="/login" title="HiDs Login - Hi All Dimensions" desc="">
      <div className="login">
        <div className="login_container">
        <form>
          <TextField variant="outlined" margin="normal" required fullWidth id="email" label="Email Address" name="email" autoComplete="email" autoFocus />
          <TextField variant="outlined" margin="normal" required fullWidth name="password" label="Password" type="password" id="password" autoComplete="current-password" />
          <Button type="submit" fullWidth variant="contained" color="primary"  className="hids_button" > Sign In </Button>
        </form>
        </div>
      </div>
    </MainContent>
  )
}

export default Login;
