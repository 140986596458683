import React from "react";
import Helmet from 'react-helmet';
const MainContent = props => {
  const urlLink = props.path ? `https://hids.co.in${props.path}` : 'https://hids.co.in';
  return (
    <>
      <Helmet>
        <title>{props.title ? props.title : 'HIDS'}</title>
        <meta name="description" content={props.desc ? props.desc : ""} />
        <meta name="author" content="Milan kumar Panigrahi" />
        <link rel="canonical" href={urlLink} />
        <meta property="og:site_name" content="HIDS" />
        <meta property="og:url" content={urlLink} />
        <meta property="og:title" content={props.title ? props.title : 'HIDS'} />
        <meta property="og:type" content="website" />
        <meta property="og:description" content={props.desc ? props.desc : ""} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={props.title ? props.title : 'HIDS'} />
        <meta name="twitter:description" content={props.desc ? props.desc : ""} />
        <script type="application/ld+json">{`
          {
            "@context": "http://schema.org",
            "@type": "WebSite",
            "name": "${props.title ? props.title : 'HIDS'}",
            "alternateName": "Hi All Dimensions",
            "description": "${props.desc ? props.desc : ""}",
            "url": "https://hids.co.in/"
          }
    `}</script>
      </Helmet>
      <div
        className="main_content"
         style={{
          margin: `${props.noPadding ? "0px" : "15px 15px"} `,
          background: `${props.noBg ? "transparent" : "#fafafa"}`,
          minHeight: `${props.minHeight ? props.minHeight : "0"}`
        }}
      >
        {props.children}
      </div>
    </>
  );
};

export default MainContent;
