import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { routes } from './routes';
import Context from "./context";
import "./styles.scss";
import Layout from './Layout';
import NotFound from './pages/NotFound';

const App = () => {
  return (
    <Context.Provider value={{}}>
      <Layout>
        <Switch>
          {routes.map(rot =>
            <Route exact path={rot.path} component={rot.Component} key={rot.id} />
          )}
          <Route component={NotFound} />
        </Switch>
      </Layout>
    </Context.Provider>
  )
}

export default App