import React from 'react'
import { MainContent } from '../components'
import { useLocation } from "react-router-dom";

const NotFound = () => {
  window.scrollTo({ top: 0 });
  const location = useLocation();

  return (
    <MainContent noPadding minHeight="100vh" title="404" desc="Page Not Found" path={location.pathname}>
        <div className="not_found"> 
        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 511.999 511.999" style={{enableBackground: 'new 0 0 511.999 511.999'}} xmlSpace="preserve">
          <path style={{fill: '#f4af1b'}} d="M89.543,315.578H20.297c-7.213,0-12.693-4.905-12.693-13.272c0-2.308,38.08-141.95,38.08-141.95
		c3.462-7.214,9.81-9.81,15.868-9.81c6.636,0,23.277,4.477,23.277,13.133c0,1.443,0.071,4.466-0.796,6.197L55.784,280.667h33.757
		v-30.294c0-8.366,9.52-11.829,18.753-11.829c9.522,0,18.754,3.462,18.754,11.829v30.294h13.56c7.79,0,11.83,8.656,11.83,17.6
		c0,8.655-5.772,17.312-11.83,17.312h-13.56v34.045c0,7.79-9.232,11.829-18.754,11.829c-9.232,0-18.753-4.039-18.753-11.829v-34.045
		H89.543z" />
          <path style={{fill: '#f4af1b'}} d="M386.519,201.245c6.254-23.041,11.122-40.89,11.122-40.89c3.462-7.214,9.81-9.81,15.868-9.81
		c6.636,0,23.277,4.477,23.277,13.133c0,1.443,0.071,4.466-0.796,6.197l-28.249,110.79h33.757v-30.294
		c0-8.366,9.52-11.829,18.753-11.829c9.522,0,18.754,3.462,18.754,11.829v30.294h13.56c7.79,0,11.83,8.656,11.83,17.6
		c0,8.655-5.772,17.312-11.83,17.312h-13.56v34.045c0,7.79-9.232,11.829-18.754,11.829c-9.232,0-18.753-4.039-18.753-11.829v-34.045
		h-69.245c-7.213,0-12.693-4.905-12.693-13.272c0-1.156,9.547-36.744,19.082-71.993L386.519,201.245z" />
        <circle style={{fill: '#f4af1b'}} cx="256.009" cy="262.861" r="85.853" />
        <path style={{fill: '#1e2548'}} d="M140.61,273.063h-5.956v-22.69c0-11.623-10.593-19.433-26.358-19.433s-26.357,7.81-26.357,19.433v22.69H65.571
	l25.677-100.707c1.101-2.745,1.194-5.749,1.186-8.392l-0.001-0.284c0-15.632-22.966-20.737-30.881-20.737
	c-10.132,0-18.414,5.148-22.723,14.123c-0.199,0.415-0.359,0.847-0.481,1.29C0,298.98,0,300.981,0,302.305
	c0,12.096,8.537,20.876,20.297,20.876H33.97c4.2,0,7.604-3.405,7.604-7.604c0-4.199-3.404-7.604-7.604-7.604H20.297
	c-3.184,0-4.887-1.694-5.072-5.04c1.954-8.608,23.256-87.252,37.593-139.831c1.785-3.288,4.721-4.954,8.734-4.954
	c5.289,0,14.355,3.338,15.673,5.732l0.001,0.128c0.001,0.584,0.006,2.084-0.132,2.756c-0.178,0.397-0.321,0.809-0.43,1.231
	L48.417,278.788c-0.58,2.275-0.076,4.69,1.365,6.544c1.44,1.854,3.656,2.938,6.004,2.938h33.757c4.2,0,7.604-3.405,7.604-7.604
	v-30.294c0-2.528,5.766-4.225,11.149-4.225c5.481,0,11.15,1.581,11.15,4.225v30.294c0,4.199,3.404,7.604,7.604,7.604h13.56
	c2.252,0,4.226,4.671,4.226,9.996c0,5.014-3.022,9.171-4.382,9.708H127.05c-4.2,0-7.604,3.405-7.604,7.604v34.045
	c0,1.954-4.872,4.225-11.15,4.225c-6.467,0-11.149-2.523-11.149-4.225v-34.045c0-4.199-3.404-7.604-7.604-7.604H59.318
	c-4.2,0-7.604,3.405-7.604,7.604c0,4.199,3.404,7.604,7.604,7.604h22.621v26.441c0,11.26,11.085,19.433,26.357,19.433
	c15.52,0,26.358-7.991,26.358-19.433v-26.441h5.956c9.192,0,19.434-10.232,19.434-24.916
	C160.043,283.663,151.87,273.063,140.61,273.063z" />
        <path style={{fill: '#1e2548'}} d="M492.566,273.063h-5.956v-22.69c0-11.623-10.593-19.433-26.358-19.433s-26.357,7.81-26.357,19.433v22.69h-16.368
	l25.679-100.708c1.1-2.746,1.193-5.75,1.185-8.393l-0.001-0.281c0-15.632-22.966-20.737-30.881-20.737
	c-10.132,0-18.415,5.148-22.724,14.123c-0.199,0.415-0.359,0.847-0.481,1.29c0,0-4.869,17.852-11.125,40.898
	c0,0.001,0,0.002-0.001,0.003l-7.879,29.071c-19.345,71.527-19.345,72.449-19.345,73.978c0,12.096,8.537,20.876,20.298,20.876h61.64
	v26.441c0,11.26,11.085,19.433,26.357,19.433c15.52,0,26.358-7.991,26.358-19.433v-26.441h5.956
	c9.192,0,19.434-10.232,19.434-24.915C512,283.663,503.826,273.063,492.566,273.063z M492.41,307.974h-13.404
	c-4.2,0-7.604,3.405-7.604,7.604v34.045c0,1.954-4.872,4.225-11.15,4.225c-6.467,0-11.149-2.523-11.149-4.225v-34.045
	c0-4.199-3.404-7.604-7.604-7.604h-69.244c-3.184,0-4.887-1.694-5.073-5.037c0.677-2.966,4.201-16.655,18.801-70.634l7.877-29.064
	c5.504-20.276,9.934-36.53,10.916-40.133c1.785-3.288,4.721-4.954,8.735-4.954c5.289,0,14.354,3.338,15.673,5.732l0.001,0.124
	c0.001,0.585,0.006,2.09-0.133,2.762c-0.177,0.396-0.32,0.808-0.429,1.23l-28.249,110.79c-0.58,2.275-0.076,4.69,1.365,6.544
	c1.44,1.854,3.656,2.938,6.004,2.938H441.5c4.2,0,7.604-3.405,7.604-7.604v-30.294c0-2.528,5.766-4.225,11.149-4.225
	c5.481,0,11.15,1.581,11.15,4.225v30.294c0,4.199,3.404,7.604,7.604,7.604h13.56c2.252,0,4.226,4.671,4.226,9.996
	C496.792,303.281,493.769,307.437,492.41,307.974z" />
        <path style={{fill: '#1e2548'}} d="M320.25,205.403c-3.362,2.515-4.05,7.281-1.535,10.643c10.168,13.597,15.542,29.786,15.542,46.818
	c0,43.149-35.104,78.252-78.252,78.252c-43.15,0-78.253-35.104-78.253-78.252s35.104-78.253,78.253-78.253
	c17.152,0,33.434,5.444,47.083,15.744c3.353,2.53,8.122,1.863,10.651-1.49c2.529-3.352,1.862-8.12-1.49-10.65
	c-16.311-12.307-35.76-18.812-56.245-18.812c-51.534,0-93.461,41.927-93.461,93.461s41.927,93.46,93.461,93.46
	s93.46-41.926,93.46-93.46c0-20.341-6.422-39.679-18.572-55.925C328.38,203.576,323.612,202.888,320.25,205.403z" />
        <path style={{fill: '#1e2548'}} d="M296.622,222.247c-2.971-2.97-7.784-2.97-10.754,0l-29.863,29.863l-29.863-29.863c-2.971-2.97-7.784-2.97-10.754,0
	c-2.97,2.97-2.97,7.784,0,10.753l29.863,29.863l-29.863,29.863c-2.97,2.97-2.97,7.784,0,10.753c1.485,1.484,3.432,2.227,5.377,2.227
	c1.946,0,3.892-0.742,5.377-2.227l29.863-29.863l29.863,29.863c1.485,1.484,3.432,2.227,5.377,2.227s3.892-0.742,5.377-2.227
	c2.97-2.97,2.97-7.784,0-10.753l-29.863-29.863L296.624,233C299.591,230.032,299.591,225.217,296.622,222.247z" />
      </svg>
        </div>
    </MainContent>
  )
}

export default NotFound
