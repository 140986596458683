import React from 'react'
import { solutions } from '../db';
import SolutionCard from './SolutionCard';

const SolutionSec = () => {
  return (
    <div className="solution_sec">
      <h2 className="heading">Our Approach</h2>
      {solutions.length > 0 &&
        <div className="solution_list_container">
          {solutions.map(sol =>
            <SolutionCard data={sol} key={sol.id} />
          )}
        </div>
      }
    </div>
  )
}

export default SolutionSec;
